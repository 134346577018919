import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

const Busy: React.FC<{ view: __esri.MapView | __esri.SceneView }> = props => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        props.view.watch('updating', () => {
            setLoading(props.view.updating);
        });
    }, [props.view]);

    return (
        <div className={loading ? 'esri-widget--button esri-widget esri-interactive' : 'd-none'} title="Loading">
            <span className="fa fa-spinner fa-spin" />
        </div>
    );
};

const CALoadingWidget = (view: __esri.MapView | __esri.SceneView) => {
    const node = document.createElement('div');

    ReactDOM.render(<Busy view={view} />, node);

    return node;
};

export default CALoadingWidget;
