import { loadModules } from 'esri-loader';
import { CAGetBufferGraphic } from './CABufferHelpers';
import { CA_COLORS } from './CAConstants';

interface ICADrawPolygonProps {
    evt: __esri.PolygonDrawAction;
    view: __esri.MapView | __esri.SceneView;
    complete: boolean;
    sketchLayer: __esri.GraphicsLayer;
}

export const CADrawPolygon = async ({ evt, view, complete, sketchLayer }: ICADrawPolygonProps) => {
    type esriModules = [
        typeof import('esri/Graphic'),
        typeof import('esri/geometry/geometryEngine'),
        typeof import('esri/geometry/Polygon'),
        typeof import('esri/geometry/Polyline'),
        typeof import('esri/symbols/SimpleLineSymbol'),
        typeof import('esri/symbols/SimpleFillSymbol')
    ];

    const [Graphic, geometryEngine, Polygon, Polyline, SimpleLineSymbol, SimpleFillSymbol] = await (loadModules([
        'esri/Graphic',
        'esri/geometry/geometryEngine',
        'esri/geometry/Polygon',
        'esri/geometry/Polyline',
        'esri/symbols/SimpleLineSymbol',
        'esri/symbols/SimpleFillSymbol'
    ]) as Promise<esriModules>);

    const densifyPolyline = (polyline: __esri.Polyline) => {
        const simplifiedPolyline = geometryEngine.simplify(polyline) as __esri.Polyline;
        return geometryEngine.geodesicDensify(simplifiedPolyline, 100, 'miles') as __esri.Polyline;
    };

    const lineSymbol = new SimpleLineSymbol({
        color: CA_COLORS.Primary,
        width: 2.5
    });

    const polygonSymbol = new SimpleFillSymbol({
        color: CA_COLORS.PrimaryTransparent,
        outline: lineSymbol
    });

    view.graphics.removeAll();
    const graphic = await CAGetBufferGraphic();

    if (evt.vertices.length === 2) {
        const polyline = new Polyline({
            paths: [evt.vertices],
            spatialReference: view.spatialReference
        });

        const densifiedPolyline = densifyPolyline(polyline);

        const graphic = new Graphic({
            geometry: densifiedPolyline,
            symbol: lineSymbol
        });

        view.graphics.add(graphic);
    } else if (evt.vertices.length >= 2) {
        const polygon = new Polygon({
            rings: [evt.vertices],
            spatialReference: view.spatialReference
        });
        const densifiedPolygon = geometryEngine.geodesicDensify(polygon, 100, 'miles') as __esri.Polygon;

        graphic.geometry = densifiedPolygon || polygon;
        graphic.symbol = polygonSymbol;
        view.graphics.add(graphic);

        if (complete) {
            view.graphics.removeAll();
            return graphic;
        }
    }
};
