import { loadModules } from 'esri-loader';

export const bufferLayerTitle = 'Buffer';

const CABufferLayer = async () => {
    type esriModules = [typeof import('esri/layers/GraphicsLayer')];

    const [GraphicsLayer] = await (loadModules(['esri/layers/GraphicsLayer']) as Promise<esriModules>);

    return new GraphicsLayer({
        title: bufferLayerTitle,
        opacity: 1.0,
        visible: true,
        listMode: 'hide'
    });
};

export default CABufferLayer;
