import { loadModules } from 'esri-loader';
import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import JSZip from 'jszip';
import { WIDGET_BUTTON_CLASS } from '../helpers/CAConstants';

interface FilesToZip {
    fileName: string;
    csv: string;
}

const getCSV = (featureSet: __esri.FeatureSet): string => {
    let csv = '';
    const setupHeader = (fields: __esri.Field[]) => {
        const headerNames: string[] = [];
        fields.forEach(f => {
            headerNames.push(`"${f.name}"`);
        });

        csv += headerNames.join(',');
        csv += '\n';
    };

    const setupValues = (fields: __esri.Field[]) => {
        featureSet.features.forEach(feature => {
            const values: string[] = [];
            fields.forEach(field => {
                values.push(feature.attributes[field.name]);
            });
            csv += values.join(',');
            csv += '\n';
        });
    };

    if (featureSet.features.length) {
        const fields = (featureSet.features[0].layer as __esri.FeatureLayer).fields;
        setupHeader(fields);
        setupValues(fields);
    }

    return csv;
};

const makeZIP = (files: FilesToZip[]) => {
    if (files) {
        // const JSZip = require('https://cdnjs.cloudflare.com/ajax/libs/jszip/3.1.5/jszip.min.js');
        const zip = new JSZip();
        files.forEach(f => {
            zip.file(f.fileName.replace('|', '-'), f.csv);
        });

        zip.generateAsync({ type: 'blob' }).then(content => {
            doDownload(content);
        });
    }
};

const doDownload = (content: any) => {
    // reportLoading.classList.add('hidden');
    const data = content; // https://stackoverflow.com/a/23451803
    const url = window.URL.createObjectURL(data);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display:none;');
    a.href = url;
    a.download = 'points.zip';
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
};

const ExportCSVButton: React.FC<{ view: __esri.MapView | __esri.SceneView }> = props => {
    const [working, setWorking] = useState(false);

    const onClick = async () => {
        setWorking(true);

        type esriModules = [typeof import('esri/tasks/support/Query')];

        const [Query] = await (loadModules(['esri/tasks/support/Query']) as Promise<esriModules>);

        const extent = props.view.extent;

        const visibleLayerViews = props.view.allLayerViews.filter(lv => {
            return (
                lv.layer.visible &&
                lv.layer.type === 'feature' &&
                (lv.layer as __esri.FeatureLayer).geometryType === 'point' &&
                lv.layer.id !== 'layerPGR'
            ); // we can only do feature layers for now
        });

        if (visibleLayerViews.length) {
            const filesToZip: FilesToZip[] = [];
            const promises: Promise<any>[] = [];

            const query = new Query({
                outFields: ['*'],
                returnGeometry: true,
                outSpatialReference: {
                    wkid: 4326
                },
                spatialRelationship: 'envelope-intersects',
                geometry: extent
            });

            visibleLayerViews.forEach(lv => {
                const fLayer = lv.layer as __esri.FeatureLayer; // query the feature layer directly since querying the featurelayerview will return coords that are quantized and only as accurate as the current scale.
                if (fLayer.definitionExpression !== '1<>1') {
                    promises.push(
                        new Promise((resolve, reject) => {
                            const existingLayerQuery = fLayer.createQuery();
                            query.where = existingLayerQuery.where;
                            fLayer
                                .queryFeatures(query)
                                .then(r => {
                                    if (r.features.length > 0) {
                                        const csv = getCSV(r);
                                        filesToZip.push({
                                            fileName: `${fLayer.title}.csv`,
                                            csv: csv
                                        });
                                    }

                                    resolve();
                                })
                                .catch(err => {
                                    reject(err);
                                });
                        })
                    );
                }
            });

            Promise.all(promises)
                .then(() => {
                    makeZIP(filesToZip);
                    setWorking(false);
                })
                .catch(err => {
                    console.error(err);
                    setWorking(false);
                });
        }
    };

    return (
        <div {...WIDGET_BUTTON_CLASS} title="Export CSV" onClick={onClick}>
            <span className={working ? 'fa fa-spinner fa-spin' : 'fa fa-file-download'} />
        </div>
    );
};

const CAExportCSVWidget = (props: { view: __esri.MapView | __esri.SceneView }) => {
    const node = document.createElement('div');
    ReactDOM.render(<ExportCSVButton view={props.view} />, node);
    return node;
};

export default CAExportCSVWidget;
