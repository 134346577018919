import { loadModules } from 'esri-loader';
import { CXYLayerLabels } from '../../helpers/CALayerLabels';
import CXYPopup from '../../popups/CXYPopup';

export const chainGroceryTitle = 'Chain Grocery';

const CXChainGroceryLayer = async () => {
    type layerModules = [typeof import('esri/layers/FeatureLayer'), typeof import('esri/layers/GroupLayer')];

    const [FeatureLayer, GroupLayer] = await (loadModules([
        'esri/layers/FeatureLayer',
        'esri/layers/GroupLayer'
    ]) as Promise<layerModules>);

    const urls = [
        {
            suffix: `0-H`,
            url:
                'https://services8.arcgis.com/osXWqrTKrwWBat15/arcgis/rest/services/CXY_Grocery_Zero_H/FeatureServer/0'
        },
        {
            suffix: `I-Q`,
            url: 'https://services8.arcgis.com/osXWqrTKrwWBat15/arcgis/rest/services/CXY_Grocery_IQ/FeatureServer/0'
        },
        {
            suffix: `R-Z`,
            url: 'https://services8.arcgis.com/osXWqrTKrwWBat15/arcgis/rest/services/CXY_Grocery_RZ/FeatureServer/0'
        }
    ];

    const labels = await CXYLayerLabels();
    const popupTemplate = await CXYPopup();

    const layers = urls.map(u => {
        return new FeatureLayer({
            url: u.url,
            title: `${chainGroceryTitle} | ${u.suffix}`,
            visible: true,
            definitionExpression: '1<>1',
            labelsVisible: false,
            legendEnabled: false,
            labelingInfo: [labels],
            popupTemplate
        });
    });

    return new GroupLayer({
        layers,
        title: chainGroceryTitle,
        opacity: 1.0,
        visible: true,
        listMode: 'hide-children'
    });
};

export default CXChainGroceryLayer;
