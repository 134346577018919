import { loadModules } from 'esri-loader';

const CALocateWidget = async (view: __esri.View) => {
    type homeModules = [typeof import('esri/widgets/Locate')];

    const [Locate] = await(loadModules(['esri/widgets/Locate']) as Promise<homeModules>);

    return new Locate({ view });
};

export default CALocateWidget;
