import React, { useState } from 'react';
import ReactDom from 'react-dom';
import { loadModules } from 'esri-loader';
import { Table, Button, Input } from 'reactstrap';
import format from 'number-format.js';
import { customBufferValueName, CACustomReport, CARingReport, CADriveReport } from '../helpers/CABufferHelpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faFilePdf, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useInputValue } from '../helpers/CAHooks';

interface ICABufferDataDict {
    [key: string]: string;
}

const dollarFields = [
    'MEDHINC_CY',
    'AVGHINC_CY',
    'MEDVAL_CY',
    'X1003_X',
    'RTTRDSALES',
    'RETTRDPOT',
    'RSALES4451',
    'RETPOT4451',
    'X1003_A'
];

const dataDict: ICABufferDataDict = {
    TOTPOP: '2022 Population',
    DPOP_CY: '2022 Daytime Population',
    TOTHH: '2022 Households',
    MEDHINC_CY: '2022 Median HHI',
    AVGHINC_CY: '2022 Average HHI',
    OWNER_CY: '2022 Owner Occupied',
    RENTER_CY: '2022 Renter Occupied',
    MEDVAL_CY: '2022 Median Home Value',
    TOTPOP_FY: '2027 Population',
    POPGRW1020: '2010-2020 Population Growth',
    POPGRWCYFY: '2022-2027 Population Growth',
    TOTPOP10: '2010 Population',
    ASIAN_CY: '2022 Asian %',
    BLACK_CY: '2022 AA %',
    HISPPOP_CY: '2022 Hispanic %',
    WHITE_CY: '2022 White %',
    ASSCDEG_CY: '2022 Assoc. Degree %',
    BACHDEG_CY: '2022 Bach. Degree %',
    GRADDEG_CY: '2022 Grad/Prof Degree %',
    HINC0_CY: '2022 HHI < $15,000',
    HINC15_CY: '2022 HHI $15,000-$24,999',
    HINC25_CY: '2022 HHI $25,000-$34,999',
    HINC35_CY: '2022 HHI $35,000-$49,999',
    HINC50_CY: '2022 HHI $50,000-$74,999',
    HINC75_CY: '2022 HHI $75,000-$99,999',
    HINC100_CY: '2022 HHI $100,000-$149,999',
    HINC150_CY: '2022 HHI $150,000-$199,999',
    HINC200_CY: '2022 HHI >= $200,000',
    X1003_X: '2022 Food at Home $',
    X1003_A: '2022 Food at Home: Avg. $',
    X1003_I: '2022 Food at Home: Index',
    RTTRDSALES: '2022 Retail Trade Sales'
    // RETTRDPOT: '2022 Retail Trade Sales Potential', // doesnt seem to be available anymore
    // LSFRETTRD: '2022 Lkg/Surplus Fctr: Retail Trade',
    // RSALES4451: '2022 Retail Sales: Grocery',
    // RETPOT4451: '2022 Ret Sales Potential: Grocery',
    // LSF4451: '2022 Lkg/Surplus Fctr: Grocery'
};

const percentOfPopulationBaseFields = [
    'WHITE_CY',
    'BLACK_CY',
    'ASIAN_CY',
    'HISPPOP_CY',
    'ASSCDEG_CY',
    'BACHDEG_CY',
    'GRADDEG_CY'
];

const percentOfHouseholdBaseFields = [
    'HINC0_CY',
    'HINC15_CY',
    'HINC25_CY',
    'HINC35_CY',
    'HINC50_CY',
    'HINC75_CY',
    'HINC100_CY',
    'HINC150_CY',
    'HINC200_CY'
];

const precalPercentFields = ['POPGRW10CY', 'POPGRWCYFY'];

const CAExportBufferCSV = (headers: string[], rows: any, lat: number, lng: number, title?: string) => {
    let csv = headers.join(',');

    if (title) {
        csv = `"${title}"\n` + csv;
    }

    csv += '\n';
    csv += `Lat/Lng,${lat} ${lng}\n`;

    Object.keys(rows).forEach((r: any) => {
        const dataName = dataDict[r];
        csv += `"${dataName}",`;
        csv += rows[r].map((r: any) => `"${r}"`).join(',');
        csv += '\n';
    });

    const a = document.createElement('a');
    a.href = 'data:text/tsv;charset=utf-8,' + csv;
    a.setAttribute('style', 'display: none;');
    a.setAttribute('download', `${new Date().toISOString()}_${lat}_${lng}.csv`);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
};

const CABufferPopup = async () => {
    type esriModules = [typeof import('esri/PopupTemplate'), typeof import('esri/geometry/Point')];

    const [PopupTemplate, Point] = await (loadModules(['esri/PopupTemplate', 'esri/geometry/Point']) as Promise<
        esriModules
    >);

    return new PopupTemplate({
        outFields: ['*'],
        title: (feature: __esri.Feature) => {
            const atts = feature.graphic.attributes;

            if (atts.bufferValues.indexOf(customBufferValueName) === 0) {
                return 'Custom Buffer Area';
            }
            return `${atts.NAME} at ${atts.lat}, ${atts.lng}`;
        },
        content: (feature: __esri.Feature) => {
            const atts = feature.graphic.attributes;
            const node = document.createElement('div');
            const rows: any = {};

            // console.log(atts);

            atts.enrichResults.forEach((resultSet: any) => {
                Object.keys(resultSet).forEach(k => {
                    if (k in dataDict) {
                        let value = resultSet[k];

                        if (percentOfPopulationBaseFields.indexOf(k) >= 0) {
                            const percentValue = (value / resultSet.TOTPOP) * 100;
                            value = `${Math.round(percentValue * 100) / 100}%`;
                        } else if (percentOfHouseholdBaseFields.indexOf(k) >= 0) {
                            const percentValue = (value / resultSet.TOTHH) * 100;
                            value = `${Math.round(percentValue * 100) / 100}%`;
                        } else if (dollarFields.indexOf(k) >= 0) {
                            value = `$${format('###,###.', value)}`;
                        } else if (precalPercentFields.indexOf(k) >= 0) {
                            value = `${value}%`;
                        } else if (k !== 'LSF4451') {
                            value = format('###,###.', value);
                        }

                        if (k in rows) {
                            rows[k].push(value);
                        } else {
                            rows[k] = [value];
                        }
                    }
                });
            });

            const headers = atts.enrichResults.map((e: any) => e.bufferDesc);
            headers.unshift('Demo');

            const doCSV = (title?: string) => {
                CAExportBufferCSV(headers, rows, atts.lat, atts.lng, title);
            };

            ReactDom.render(
                <CABufferContent feature={feature} Point={Point} doCSV={doCSV} headers={headers} rows={rows} />,
                node
            );

            return node;
        }
    });
};

interface ICABufferContentProps {
    doCSV: (title?: string) => void;
    headers: any;
    rows: any;
    feature: __esri.Feature;
    Point: __esri.PointConstructor;
}

const CABufferContent: React.FC<ICABufferContentProps> = props => {
    const titleInput = useInputValue('');
    const [reportLoading, setReportLoading] = useState(false);

    const doReport = async (title?: string) => {
        setReportLoading(true);
        const atts = props.feature.graphic.attributes;

        switch (atts.NAME) {
            case 'Ring Buffer':
                await CARingReport({
                    title,
                    distances: atts.bufferValues,
                    point: new props.Point({ latitude: atts.lat, longitude: atts.lng })
                });
                setReportLoading(false);
                break;
            case 'Drivetime Buffer':
                await CADriveReport({
                    title,
                    distances: atts.bufferValues,
                    point: new props.Point({ latitude: atts.lat, longitude: atts.lng })
                });
                setReportLoading(false);
                break;
            case customBufferValueName:
                await CACustomReport({
                    graphics: [props.feature.graphic]
                });
                setReportLoading(false);
                break;
            default:
                break;
        }
    };

    const handlCSVClick = () => {
        props.doCSV(titleInput.value);
    };

    return (
        <React.Fragment>
            <Input
                className="my-2"
                type="text"
                placeholder="Report Title"
                onChange={titleInput.onChange}
                value={titleInput.value}
            />
            <div className="d-flex justify-content-end mb-3">
                <Button color="primary" onClick={handlCSVClick} size="sm">
                    CSV
                    <FontAwesomeIcon icon={faDownload} className="ml-1" />
                </Button>
                <Button
                    color="info"
                    disabled={reportLoading}
                    onClick={() => {
                        doReport(titleInput.value);
                    }}
                    size="sm"
                >
                    Report
                    <FontAwesomeIcon
                        icon={reportLoading ? faSpinner : faFilePdf}
                        spin={reportLoading}
                        className="ml-1"
                    />
                </Button>
            </div>
            <Table hover size="sm">
                <thead>
                    <tr>
                        {props.headers.map((header: string, idx: number) => {
                            return <th key={idx}>{header}</th>;
                        })}
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(props.rows).map((r: any, idx: number) => {
                        const dataName = dataDict[r];
                        const values = props.rows[r];
                        return (
                            <tr key={idx}>
                                <th scope="row">{dataName}</th>
                                {values.map((v: any, vidx: number) => {
                                    return <td key={vidx}>{v}</td>;
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        </React.Fragment>
    );
};

export default CABufferPopup;
