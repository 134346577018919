import { loadModules } from 'esri-loader';
import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useInputValue } from '../helpers/CAHooks';
import { Input, Button, ButtonGroup, InputGroup, InputGroupAddon, FormGroup } from 'reactstrap';
import classNames from 'classnames';
import Switch from 'react-switch';
import { FixedSizeList as List } from 'react-window';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { chainGroceryTitle } from '../layers/cxy/CXYChainGroceryLayer';

interface ICAGroupLayerListFilterWidgetItem {
    label: string;
    logo: HTMLElement;
    visible: boolean;
    layer: __esri.FeatureLayer;
}

const sortItems = (a: ICAGroupLayerListFilterWidgetItem, b: ICAGroupLayerListFilterWidgetItem) => {
    if (a.label.toLowerCase() > b.label.toLowerCase()) {
        return 1;
    }

    if (a.label.toLowerCase() < b.label.toLowerCase()) {
        return -1;
    }

    return 0;
};

const getLogoHTML = async (symbol: __esri.Symbol) => {
    type esriModules = [typeof import('esri/symbols/support/symbolUtils')];
    const [symbolUtils] = await (loadModules(['esri/symbols/support/symbolUtils']) as Promise<esriModules>);
    let logo: HTMLElement;

    if (symbol.type === 'picture-marker') {
        const img = document.createElement('img');
        img.height = 24;

        img.src = (symbol as __esri.PictureMarkerSymbol).url;
        logo = img;
    } else {
        let size = 8;
        if (symbol.type === 'simple-marker') {
            size = (symbol as __esri.SimpleMarkerSymbol).size;
        }

        logo = await symbolUtils.renderPreviewHTML(symbol, { size });
    }

    return logo;
};

const CAGroupLayerListByFilterWidgetContent: React.FC<{ layer: __esri.GroupLayer }> = props => {
    const searchText = useInputValue('');
    const [items, setItems] = useState<ICAGroupLayerListFilterWidgetItem[]>([]);
    const [labelsVisible, setLabelsVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [query, setQuery] = useState('1<>1');

    useEffect(() => {
        props.layer.layers.forEach(l => {
            const fLayer = l as __esri.FeatureLayer;
            if (query !== fLayer.definitionExpression) {
                fLayer.definitionExpression = query;
            }
        });
    }, [query, props.layer]);

    useEffect(() => {
        const init = () => {
            const storeItems: ICAGroupLayerListFilterWidgetItem[] = [];
            const promises: any = [];

            props.layer.layers.forEach(l => {
                if (l.type === 'feature') {
                    const fLayer = l as __esri.FeatureLayer;
                    const renderer = fLayer.renderer as __esri.UniqueValueRenderer;

                    // Get the start and end letter of the labels for the layer
                    const range = fLayer.title.replace(`${chainGroceryTitle} | `, '').split('-');
                    const startLetter = range[0].toLowerCase();
                    const endLetter = range[1].toLowerCase();

                    renderer.uniqueValueInfos.forEach(i => {
                        if (i.label[0].toLowerCase() >= startLetter && i.label[0].toLowerCase() <= endLetter) {
                            const promise = new Promise(async (resolve, reject) => {
                                // we have to keep track of the promises in order to know when to render the list so just create one and push to an array then resolve when the logo await is done
                                try {
                                    const logo = await getLogoHTML(i.symbol);
                                    const item: ICAGroupLayerListFilterWidgetItem = {
                                        label: i.label,
                                        logo,
                                        visible: false,
                                        layer: fLayer
                                    };

                                    storeItems.push(item);
                                    resolve();
                                } catch (error) {
                                    reject(error);
                                }
                            });

                            promises.push(promise);
                        }
                    });
                }
            });
            Promise.all(promises).then(p => {
                setItems([...storeItems.sort(sortItems)]);
            });
            setIsLoading(false);
        };

        init();
    }, [props.layer.layers]);

    useEffect(() => {
        const visibleLabels = items.filter(i => i.visible);
        if (visibleLabels.length) {
            const newQuery = `Label in (${visibleLabels.map(i => `'${i.label.replace("'", "''")}'`).join(', ')})`;
            setQuery(newQuery);
        } else {
            setQuery('1<>1');
        }
    }, [items]);

    const filterItems = (i: ICAGroupLayerListFilterWidgetItem) => {
        return searchText.value ? i.label.toLowerCase().indexOf(searchText.value.toLowerCase()) >= 0 : true;
    };

    const toggleAll = (visible: boolean) => {
        items.filter(filterItems).forEach(i => (i.visible = visible));
        setItems([...items]);
    };

    const toggleLabels = () => {
        props.layer.layers.forEach(l => {
            if (l.type === 'feature') {
                const fLayer = l as __esri.FeatureLayer;
                fLayer.labelsVisible = !fLayer.labelsVisible;
            }
        });

        setLabelsVisible(!labelsVisible);
    };

    const toggleLabel = (item: ICAGroupLayerListFilterWidgetItem) => {
        let idx = items.findIndex(i => i.label === item.label);
        item.visible = !item.visible;
        if (idx) {
            items[idx] = item;
        }
        setItems([...items]);
    };

    const loadingDiv = <h6 className="my-5 text-center">Loading...</h6>;

    const ItemRow = (rowProps: { index: number; style: any }) => {
        const item = items.filter(filterItems)[rowProps.index];
        return (
            <div className="esri-layer-list__item mb-1" style={{ ...rowProps.style }}>
                <div className="esri-layer-list__item-container">
                    <div
                        className="esri-layer-list__item-label d-flex align-items-center"
                        onClick={() => {
                            toggleLabel(item);
                        }}
                    >
                        <span className="esri-layer-list__item-toggle d-flex align-items-center">
                            <span
                                className={classNames('esri-layer-list__item-toggle-icon', {
                                    'esri-icon-visible': item.visible,
                                    'esri-icon-non-visible': !item.visible
                                })}
                            />
                        </span>
                        <span
                            className="esri-layer-list__item-title d-flex align-items-center"
                            title={item.label}
                            aria-label={item.label}
                        >
                            <span dangerouslySetInnerHTML={{ __html: item.logo.outerHTML }}></span>
                            <span className="ml-1">{item.label}</span>
                        </span>
                    </div>
                </div>
            </div>
        );
    };

    const controls = (
        <React.Fragment>
            <FormGroup className="mb-2">
                <InputGroup size="sm">
                    <Input
                        placeholder={`Search ${props.layer.title}`}
                        type="text"
                        value={searchText.value}
                        onChange={searchText.onChange}
                    />
                    <InputGroupAddon addonType="append">
                        <Button
                            aria-label="Clear Search Text"
                            title="Clear Search Text"
                            disabled={searchText.value.length === 0 ? true : false}
                            // className={classNames({ 'd-none': searchText.value.length === 0 })}
                            onClick={() => {
                                searchText.setValue('');
                            }}
                        >
                            <FontAwesomeIcon icon={faTimesCircle} />
                        </Button>
                    </InputGroupAddon>
                </InputGroup>
            </FormGroup>
            <div className="d-flex align-items-center align-items-center justify-content-between">
                <label className="d-flex align-items-center align-items-center mb-0 mr-2">
                    Labels
                    <Switch height={21} width={42} className="ml-1" checked={labelsVisible} onChange={toggleLabels} />
                </label>
                <ButtonGroup className="my-1" size="sm">
                    <Button
                        color="success"
                        onClick={() => {
                            toggleAll(true);
                        }}
                    >
                        All On
                    </Button>
                    <Button
                        color="warning"
                        onClick={() => {
                            toggleAll(false);
                        }}
                    >
                        All Off
                    </Button>
                </ButtonGroup>
            </div>
            <List
                itemSize={48}
                itemCount={items.filter(filterItems).length}
                height={540}
                width="auto"
                className="esri-layer-list__list esri-layer-list__list--root esri-layer-list__list--independent"
            >
                {ItemRow}
            </List>
        </React.Fragment>
    );

    return <div className="esri-layer-list esri-widget esri-widget--panel">{isLoading ? loadingDiv : controls}</div>;
};

interface ICALayerListByFilterWidget {
    view: __esri.MapView | __esri.SceneView;
    layerTitle: string;
    expandIconClass: string;
}

const CAChainGroceryWidget = async (props: ICALayerListByFilterWidget) => {
    type esriModules = [typeof import('esri/widgets/Expand'), typeof import('esri/core/watchUtils')];

    const [Expand, watchUtils] = await (loadModules(['esri/widgets/Expand', 'esri/core/watchUtils']) as Promise<
        esriModules
    >);

    const content = document.createElement('div');

    const expand = new Expand({
        view: props.view,
        expandTooltip: props.layerTitle,
        expandIconClass: props.expandIconClass,
        content
    });

    watchUtils.whenOnce(expand, 'expanded', () => {
        const layer = props.view.map.allLayers.find(l => l.title === props.layerTitle);
        if (layer && layer.type === 'group') {
            ReactDOM.render(<CAGroupLayerListByFilterWidgetContent layer={layer as __esri.GroupLayer} />, content);
        } else {
            throw new Error('Layer must be a FeatureLayer.');
        }
    });

    return expand;
};

export default CAChainGroceryWidget;
