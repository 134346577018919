import { loadModules } from 'esri-loader';
import { CXYLayerLabels } from '../../helpers/CALayerLabels';
import { getCALayerId } from '../../helpers/CALayerID';
import CXYPopup from '../../popups/CXYPopup';

const CXYBankLayer = async () => {
    type esriModules = [typeof import('esri/layers/FeatureLayer')];

    const [FeatureLayer] = await (loadModules(['esri/layers/FeatureLayer']) as Promise<esriModules>);

    const title = 'Banks';

    return new FeatureLayer({
        title,
        id: getCALayerId(title),
        visible: false,
        url: 'https://services8.arcgis.com/osXWqrTKrwWBat15/arcgis/rest/services/CXY_Banks/FeatureServer/0',
        labelingInfo: [await CXYLayerLabels()],
        popupTemplate: await CXYPopup(),
        legendEnabled: false
    });
};

export default CXYBankLayer;
