import { loadModules } from 'esri-loader';
import { getSupportingLayerId } from '../../helpers/CALayerID';
import React from 'react';
import ReactDom from 'react-dom';
import { Row, Table } from 'reactstrap';
import NumberFormat from 'react-number-format';
import CASortLayers from '../../helpers/CASortLayers';
import { DEMOGRPAHICS_YEAR_CY, DEMOGRAPHICS_YEAR_FY } from '../../helpers/CAConstants';

export const mhiCALayerTitle = `${DEMOGRPAHICS_YEAR_CY} Median Household Income (CA)`;

const url = 'https://demographics5.arcgis.com/arcgis/rest/services/USA_Demographics_and_Boundaries_2022/MapServer';

const outFields = [
    'ID',
    'NAME',
    'MEDHINC_CY',
    'MEDHINC_FY',
    'BACHDEG_CY',
    'GRADDEG_CY',
    'POPGRWCYFY',
    'TOTPOP_CY',
    'TOTPOP_FY',
    'TLIFENAME',
    'TSEGNAME',
    'TSEGNUM',
    'EDUCBASECY',
    'BLACK_CY',
    'BLACK_FY',
    'HISPPOP_CY',
    'HISPPOP_FY',
    'POPDENS_CY'
];

export const demoIds = [
    {
        id: 3,
        title: 'State',
        minScale: 147914400,
        maxScale: 18489401
    },
    {
        id: 7,
        title: 'County',
        minScale: 18489400,
        maxScale: 1155601
    },
    {
        id: 9,
        title: 'ZIP Code',
        minScale: 1155600,
        maxScale: 288951
    },
    {
        id: 11,
        title: 'Tract',
        minScale: 288950,
        maxScale: 72501
    },
    {
        id: 12,
        title: 'Block Group',
        minScale: 72500,
        maxScale: 0
    }
]; // Country, State, County, County, County, ZIP, Tract, Block Group

const getDemographicPopupTemplate = async () => {
    type PopupModules = [typeof import('esri/PopupTemplate')];
    const [PopupTemplate] = await (loadModules(['esri/PopupTemplate']) as Promise<PopupModules>);

    return new PopupTemplate({
        outFields,
        title: (feature: __esri.Feature) => {
            // NOTE: This is accessing a private property to get the layer name. Double check this still works when updating the Esri API
            const layerName = (feature.graphic as any).sourceLayer.title;

            if (layerName === 'County') {
                return feature.graphic.attributes.NAME;
            }

            if (layerName === 'ZIP Code') {
                return `${feature.graphic.attributes.ID} (${layerName})`;
            }

            return `${feature.graphic.attributes.NAME} (${layerName})`;
        },
        content: (feature: __esri.Feature) => {
            const node = document.createElement('div');

            const cyPop = parseFloat(feature.graphic.attributes.TOTPOP_CY);
            const fyPop = parseFloat(feature.graphic.attributes.TOTPOP_FY);
            const cyMHI = parseFloat(feature.graphic.attributes.MEDHINC_CY);
            const fyMHI = parseFloat(feature.graphic.attributes.MEDHINC_FY);

            const fourYear = parseFloat(feature.graphic.attributes.BACHDEG_CY);
            const graduate = parseFloat(feature.graphic.attributes.GRADDEG_CY);
            const eduBase = parseFloat(feature.graphic.attributes.EDUCBASECY);
            const collegePercent = ((fourYear + graduate) / eduBase) * 100;

            // AA Population
            const aaCY = parseInt(feature.graphic.attributes.BLACK_CY, 10) * 100;
            const aaFY = parseInt(feature.graphic.attributes.BLACK_FY, 10) * 100;
            const aaPercentCY = aaCY / cyPop;
            const aaPercentFY = aaFY / fyPop;

            // Hisp Population
            const hiCY = parseInt(feature.graphic.attributes.HISPPOP_CY, 10) * 100;
            const hiFY = parseInt(feature.graphic.attributes.HISPPOP_FY, 10) * 100;
            const hiPercentCY = hiCY / cyPop;
            const hiPercentFY = hiFY / fyPop;

            const tapLink = (
                <a
                    href={`https://downloads.esri.com/esri_content_doc/dbl/us/tapestry/segment${feature.graphic.attributes.TSEGNUM}.pdf`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {feature.graphic.attributes.TSEGNAME}
                </a>
            );

            const content = (
                <React.Fragment>
                    <Row className="mx-0">
                        <p>
                            The dominant tapestry here is {tapLink} which is part of the{' '}
                            {feature.graphic.attributes.TLIFENAME} Lifemode Summary Group.{' '}
                            <strong>
                                <NumberFormat value={collegePercent} decimalScale={2} displayType={'text'} />%
                            </strong>{' '}
                            of the population has attained at least a 4-year degree. The population density is{' '}
                            <strong>{feature.graphic.attributes.POPDENS_CY}</strong> persons/sqmi and the population
                            change is projected to be <strong>{feature.graphic.attributes.POPGRWCYFY}%.</strong>
                        </p>
                    </Row>
                    <Table hover size="sm">
                        <thead>
                            <tr>
                                <th>Demo</th>
                                <th>{DEMOGRPAHICS_YEAR_CY}</th>
                                <th>{DEMOGRAPHICS_YEAR_FY}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">Population</th>
                                <td>
                                    <NumberFormat value={cyPop} thousandSeparator={true} displayType={'text'} />
                                </td>
                                <td>
                                    <NumberFormat value={fyPop} thousandSeparator={true} displayType={'text'} />
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">African American Population %</th>
                                <td>
                                    <NumberFormat value={aaPercentCY} decimalScale={2} displayType={'text'} />%
                                </td>
                                <td>
                                    <NumberFormat value={aaPercentFY} decimalScale={2} displayType={'text'} />%
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">Hispanic Population %</th>
                                <td>
                                    <NumberFormat value={hiPercentCY} decimalScale={2} displayType={'text'} />%
                                </td>
                                <td>
                                    <NumberFormat value={hiPercentFY} decimalScale={2} displayType={'text'} />%
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">Median Household Income</th>
                                <td>
                                    $
                                    <NumberFormat
                                        value={cyMHI}
                                        thousandSeparator={true}
                                        decimalScale={0}
                                        displayType={'text'}
                                    />
                                </td>
                                <td>
                                    $
                                    <NumberFormat
                                        value={fyMHI}
                                        thousandSeparator={true}
                                        decimalScale={0}
                                        displayType={'text'}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </React.Fragment>
            );

            ReactDom.render(content, node);

            return node;
        }
    });
};

export const mhiCABreakRenderer = {
    type: 'classBreaks',
    minValue: 0,
    classBreakInfos: [
        {
            classMaxValue: 50000,
            symbol: {
                type: 'esriSFS',
                color: [254, 240, 217],
                outline: {
                    type: 'esriSLS',
                    color: [191, 191, 191, 255],
                    width: 1,
                    style: 'esriSLSSolid'
                },
                style: 'esriSFSSolid'
            },
            description: '',
            label: '$0 - $50,000'
        },
        {
            classMaxValue: 75000,
            symbol: {
                type: 'esriSFS',
                color: [253, 204, 138],
                outline: {
                    type: 'esriSLS',
                    color: [191, 191, 191, 255],
                    width: 1,
                    style: 'esriSLSSolid'
                },
                style: 'esriSFSSolid'
            },
            description: '',
            label: '$50,000 - $75,000'
        },
        {
            classMaxValue: 100000,
            symbol: {
                type: 'esriSFS',
                color: [252, 141, 89],
                outline: {
                    type: 'esriSLS',
                    color: [191, 191, 191, 255],
                    width: 1,
                    style: 'esriSLSSolid'
                },
                style: 'esriSFSSolid'
            },
            description: '',
            label: '$75,000 - $100,000'
        },
        {
            classMaxValue: 125000,
            symbol: {
                type: 'esriSFS',
                color: [227, 74, 51],
                outline: {
                    type: 'esriSLS',
                    color: [191, 191, 191, 255],
                    width: 1,
                    style: 'esriSLSSolid'
                },
                style: 'esriSFSSolid'
            },
            description: '',
            label: '$100,000 - $125,000'
        },
        {
            classMaxValue: 9999999,
            symbol: {
                type: 'esriSFS',
                color: [179, 0, 0],
                outline: {
                    type: 'esriSLS',
                    color: [191, 191, 191, 255],
                    width: 1,
                    style: 'esriSLSSolid'
                },
                style: 'esriSFSSolid'
            },
            description: '',
            label: '> $125,000'
        }
    ],
    classificationMethod: 'esriClassifyNaturalBreaks',
    field: 'MEDHINC_CY'
};

const MHICALayerFeatureLayer = async (props: {
    title: string;
    rendererJson: any;
    popupTemplate: __esri.PopupTemplate;
}) => {
    type LayerModules = [
        typeof import('esri/layers/FeatureLayer'),
        typeof import('esri/layers/GroupLayer'),
        typeof import('esri/renderers/ClassBreaksRenderer')
    ];

    const [FeatureLayer, GroupLayer, ClassBreaksRenderer] = await (loadModules([
        'esri/layers/FeatureLayer',
        'esri/layers/GroupLayer',
        'esri/renderers/ClassBreaksRenderer'
    ]) as Promise<LayerModules>);

    const renderer = ClassBreaksRenderer.fromJSON(mhiCABreakRenderer);

    const layers = demoIds.map(d => {
        return new FeatureLayer({
            url: `${url}/${d.id}`,
            title: d.title,
            popupTemplate: props.popupTemplate,
            renderer,
            minScale: d.minScale,
            maxScale: d.maxScale,
            outFields: outFields,
            visible: true
        });
    });

    return new GroupLayer({
        layers,
        listMode: 'hide-children',
        visible: false,
        title: props.title
    });
};

const AfricanAmericanLayer = async () => {
    type LayerModules = [
        typeof import('esri/layers/MapImageLayer'),
        typeof import('esri/renderers/ClassBreaksRenderer')
    ];
    const [MapImageLayer, ClassBreaksRenderer] = await (loadModules([
        'esri/layers/MapImageLayer',
        'esri/renderers/ClassBreaksRenderer'
    ]) as Promise<LayerModules>);

    const aaRenderer = {
        type: 'classBreaks',
        minValue: 0,
        classBreakInfos: [
            {
                classMaxValue: 0.1,
                symbol: {
                    type: 'esriSFS',
                    color: [154, 186, 200, 255],
                    outline: {
                        type: 'esriSLS',
                        color: [191, 191, 191, 255],
                        width: 1,
                        style: 'esriSLSSolid'
                    },
                    style: 'esriSFSSolid'
                },
                description: '',
                label: '0 - 10% African American'
            },
            {
                classMaxValue: 0.2,
                symbol: {
                    type: 'esriSFS',
                    color: [110, 153, 184, 184],
                    outline: {
                        type: 'esriSLS',
                        color: [191, 191, 191, 255],
                        width: 1,
                        style: 'esriSLSSolid'
                    },
                    style: 'esriSFSSolid'
                },
                description: '',
                label: '10 - 20% African American'
            },
            {
                classMaxValue: 0.3,
                symbol: {
                    type: 'esriSFS',
                    color: [71, 118, 168, 255],
                    outline: {
                        type: 'esriSLS',
                        color: [191, 191, 191, 255],
                        width: 1,
                        style: 'esriSLSSolid'
                    },
                    style: 'esriSFSSolid'
                },
                description: '',
                label: '20 - 30% African American'
            },
            {
                classMaxValue: 1,
                symbol: {
                    type: 'esriSFS',
                    color: [36, 81, 135],
                    outline: {
                        type: 'esriSLS',
                        color: [191, 191, 191, 255],
                        width: 1,
                        style: 'esriSLSSolid'
                    },
                    style: 'esriSFSSolid'
                },
                description: '',
                label: '>30% African American'
            }
        ],
        classificationMethod: 'esriClassifyNaturalBreaks',
        field: 'BLACK_CY',
        normalizationField: 'TOTPOP_CY',
        normalizationType: 'field'
    };

    const africanAmericanLayer = new MapImageLayer({
        portalItem: {
            id: 'a95db032417f40579fb68ff98ca14847'
        },
        title: `${DEMOGRPAHICS_YEAR_CY} African American Population %`,
        listMode: 'hide-children'
    });

    africanAmericanLayer.on('layerview-create', () => {
        africanAmericanLayer.sublayers.forEach(async s => {
            s.renderer = ClassBreaksRenderer.fromJSON(aaRenderer);
        });
    });

    return africanAmericanLayer;
};

const HispanicLayer = async () => {
    type LayerModules = [
        typeof import('esri/layers/MapImageLayer'),
        typeof import('esri/renderers/ClassBreaksRenderer')
    ];
    const [MapImageLayer, ClassBreaksRenderer] = await (loadModules([
        'esri/layers/MapImageLayer',
        'esri/renderers/ClassBreaksRenderer'
    ]) as Promise<LayerModules>);

    const hispanicRenderer = {
        type: 'classBreaks',
        minValue: 0,
        classBreakInfos: [
            {
                classMaxValue: 0.1,
                symbol: {
                    type: 'esriSFS',
                    color: [237, 248, 251, 255],
                    outline: {
                        type: 'esriSLS',
                        color: [191, 191, 191, 255],
                        width: 1,
                        style: 'esriSLSSolid'
                    },
                    style: 'esriSFSSolid'
                },
                description: '',
                label: '0 - 10% Hispanic'
            },
            {
                classMaxValue: 0.2,
                symbol: {
                    type: 'esriSFS',
                    color: [178, 226, 226, 184],
                    outline: {
                        type: 'esriSLS',
                        color: [191, 191, 191, 255],
                        width: 1,
                        style: 'esriSLSSolid'
                    },
                    style: 'esriSFSSolid'
                },
                description: '',
                label: '10 - 20% Hispanic'
            },
            {
                classMaxValue: 0.3,
                symbol: {
                    type: 'esriSFS',
                    color: [102, 194, 164, 255],
                    outline: {
                        type: 'esriSLS',
                        color: [191, 191, 191, 255],
                        width: 1,
                        style: 'esriSLSSolid'
                    },
                    style: 'esriSFSSolid'
                },
                description: '',
                label: '20 - 30% Hispanic'
            },
            {
                classMaxValue: 1,
                symbol: {
                    type: 'esriSFS',
                    color: [35, 139, 69, 255],
                    outline: {
                        type: 'esriSLS',
                        color: [191, 191, 191, 255],
                        width: 1,
                        style: 'esriSLSSolid'
                    },
                    style: 'esriSFSSolid'
                },
                description: '',
                label: '>30% Hispanic'
            }
        ],
        classificationMethod: 'esriClassifyNaturalBreaks',
        field: 'HISPPOP_CY',
        normalizationField: 'TOTPOP_CY',
        normalizationType: 'field'
    };

    const hispanicLayer = new MapImageLayer({
        portalItem: {
            id: 'a95db032417f40579fb68ff98ca14847'
        },
        title: `${DEMOGRPAHICS_YEAR_CY} Hispanic Population %`,
        listMode: 'hide-children'
    });

    hispanicLayer.on('layerview-create', () => {
        hispanicLayer.sublayers.forEach(s => {
            s.renderer = ClassBreaksRenderer.fromJSON(hispanicRenderer);
        });
    });

    return hispanicLayer;
};

const MHILayer = async () => {
    type LayerModules = [
        typeof import('esri/layers/MapImageLayer'),
        typeof import('esri/renderers/ClassBreaksRenderer')
    ];
    const [MapImageLayer, ClassBreaksRenderer] = await (loadModules([
        'esri/layers/MapImageLayer',
        'esri/renderers/ClassBreaksRenderer'
    ]) as Promise<LayerModules>);

    const mhiRenderer = {
        type: 'classBreaks',
        minValue: 0,
        classBreakInfos: [
            {
                classMaxValue: 11800,
                symbol: {
                    type: 'esriSFS',
                    color: [254, 240, 217],
                    outline: {
                        type: 'esriSLS',
                        color: [191, 191, 191, 255],
                        width: 1,
                        style: 'esriSLSSolid'
                    },
                    style: 'esriSFSSolid'
                },
                description: '',
                label: '$0 - $11,800'
            },
            {
                classMaxValue: 43700,
                symbol: {
                    type: 'esriSFS',
                    color: [253, 204, 138],
                    outline: {
                        type: 'esriSLS',
                        color: [191, 191, 191, 255],
                        width: 1,
                        style: 'esriSLSSolid'
                    },
                    style: 'esriSFSSolid'
                },
                description: '',
                label: '$11,800 - $43,700'
            },
            {
                classMaxValue: 75600,
                symbol: {
                    type: 'esriSFS',
                    color: [252, 141, 89],
                    outline: {
                        type: 'esriSLS',
                        color: [191, 191, 191, 255],
                        width: 1,
                        style: 'esriSLSSolid'
                    },
                    style: 'esriSFSSolid'
                },
                description: '',
                label: '$43,700 - $75,600'
            },
            {
                classMaxValue: 107500,
                symbol: {
                    type: 'esriSFS',
                    color: [227, 74, 51],
                    outline: {
                        type: 'esriSLS',
                        color: [191, 191, 191, 255],
                        width: 1,
                        style: 'esriSLSSolid'
                    },
                    style: 'esriSFSSolid'
                },
                description: '',
                label: '$75,600 - $107,500'
            },
            {
                classMaxValue: 200100,
                symbol: {
                    type: 'esriSFS',
                    color: [179, 0, 0],
                    outline: {
                        type: 'esriSLS',
                        color: [191, 191, 191, 255],
                        width: 1,
                        style: 'esriSLSSolid'
                    },
                    style: 'esriSFSSolid'
                },
                description: '',
                label: '$107,500 - $200,100'
            }
        ],
        classificationMethod: 'esriClassifyNaturalBreaks',
        field: 'MEDHINC_CY'
    };

    const mhiLayer = new MapImageLayer({
        portalItem: {
            id: 'a95db032417f40579fb68ff98ca14847'
        },
        listMode: 'hide-children'
    });

    mhiLayer.on('layerview-create', () => {
        mhiLayer.sublayers.forEach(s => {
            s.renderer = ClassBreaksRenderer.fromJSON(mhiRenderer);
        });
    });

    return mhiLayer;
};

const PopulationDensityLayer = async () => {
    type LayerModules = [
        typeof import('esri/layers/MapImageLayer'),
        typeof import('esri/renderers/ClassBreaksRenderer')
    ];
    const [MapImageLayer, ClassBreaksRenderer] = await (loadModules([
        'esri/layers/MapImageLayer',
        'esri/renderers/ClassBreaksRenderer'
    ]) as Promise<LayerModules>);

    const popDensityRenderer = {
        type: 'classBreaks',
        minValue: 0,
        classBreakInfos: [
            {
                classMaxValue: 1000,
                symbol: {
                    type: 'esriSFS',
                    color: [254, 240, 217],
                    outline: {
                        type: 'esriSLS',
                        color: [222, 192, 177, 255],
                        width: 1,
                        style: 'esriSLSSolid'
                    },
                    style: 'esriSFSSolid'
                },
                description: '',
                label: '0 - 1,000 people per sq mi'
            },
            {
                classMaxValue: 4000,
                symbol: {
                    type: 'esriSFS',
                    color: [253, 204, 138],
                    outline: {
                        type: 'esriSLS',
                        color: [222, 192, 177, 255],
                        width: 1,
                        style: 'esriSLSSolid'
                    },
                    style: 'esriSFSSolid'
                },
                description: '',
                label: '1,000 - 4,000 people per sq mi'
            },
            {
                classMaxValue: 22000,
                symbol: {
                    type: 'esriSFS',
                    color: [252, 141, 89],
                    outline: {
                        type: 'esriSLS',
                        color: [222, 192, 177, 255],
                        width: 1,
                        style: 'esriSLSSolid'
                    },
                    style: 'esriSFSSolid'
                },
                description: '',
                label: '4,000 - 22,000 people per sq mi'
            },
            {
                classMaxValue: 116000,
                symbol: {
                    type: 'esriSFS',
                    color: [227, 74, 51],
                    outline: {
                        type: 'esriSLS',
                        color: [222, 192, 177, 255],
                        width: 1,
                        style: 'esriSLSSolid'
                    },
                    style: 'esriSFSSolid'
                },
                description: '',
                label: '22,000 - 116,000 people per sq mi'
            },
            {
                classMaxValue: 618125,
                symbol: {
                    type: 'esriSFS',
                    color: [179, 0, 0],
                    outline: {
                        type: 'esriSLS',
                        color: [222, 192, 177, 255],
                        width: 1,
                        style: 'esriSLSSolid'
                    },
                    style: 'esriSFSSolid'
                },
                description: '',
                label: '116,000 - 618,125 people per sq mi'
            }
        ],
        classificationMethod: 'esriClassifyNaturalBreaks',
        field: 'POPDENS_CY'
    };

    const popDensLayer = new MapImageLayer({
        portalItem: {
            id: 'a245493a8c5344cbafc60d70170c666d'
        },
        listMode: 'hide-children'
    });

    popDensLayer.on('layerview-create', () => {
        popDensLayer.sublayers.forEach(s => {
            s.renderer = ClassBreaksRenderer.fromJSON(popDensityRenderer);
        });
    });

    return popDensLayer;
};

const TapestryLayer = async () => {
    type LayerModules = [typeof import('esri/layers/MapImageLayer')];
    const [MapImageLayer] = await (loadModules(['esri/layers/MapImageLayer']) as Promise<LayerModules>);

    return new MapImageLayer({
        portalItem: {
            id: 'c2a2e156485b4feab11b86976fe9c011'
        },
        listMode: 'hide-children'
    });
};

const CADemographicLayers = async () => {
    type LayerModules = [typeof import('esri/layers/GroupLayer')];

    const [GroupLayer] = await (loadModules(['esri/layers/GroupLayer']) as Promise<LayerModules>);

    const popupTemplate = await getDemographicPopupTemplate();

    // Set this up as a goup layer of feature layers
    // It's a lot easier to generate the renderer
    // this way using smartMapping classbreaks renderer
    const mhiCALayer: __esri.Layer = await MHICALayerFeatureLayer({
        title: mhiCALayerTitle,
        rendererJson: mhiCABreakRenderer,
        popupTemplate
    });

    const mapImageLayers: __esri.Layer[] = [
        await AfricanAmericanLayer(),
        await HispanicLayer(),
        await MHILayer(),
        await PopulationDensityLayer(),
        await TapestryLayer()
    ];

    mapImageLayers.forEach(l => {
        l.when(() => {
            (l as __esri.MapImageLayer).sublayers.forEach(s => {
                s.popupTemplate = popupTemplate;
            });
        });
    });

    const title = 'Demographics';

    const layers = mapImageLayers.concat([mhiCALayer]);

    layers.forEach(l => {
        l.id = `demo_${l.title}`;
    });

    return new GroupLayer({
        layers: layers.sort(CASortLayers),
        title,
        visible: false,
        visibilityMode: 'exclusive',
        id: getSupportingLayerId(title),
        opacity: 0.5
    });
};

export default CADemographicLayers;
