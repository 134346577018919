import { DateTime } from 'luxon';

const CAFormatDate = (value: string): string => {
    let dt = DateTime.fromISO(value);
    // console.log('value', value);
    if (!dt.isValid) {
        const millis = parseInt(value, 10);
        // console.log('millis', millis);
        if (millis > 10000) {
            dt = DateTime.fromMillis(millis);
        }
    }
    // console.log(dt.isValid, dt);
    if (dt.isValid) {
        if (dt.month >= 1 && dt.day > 1) {
            // if date is > 1/1 send the full date. this is not ideal but the best we can do with the data provided
            return dt.toLocaleString();
        } else {
            return dt.year.toString();
        }
    }

    return 'N/A';
};

export default CAFormatDate;