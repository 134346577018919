import React, { useEffect } from 'react';
import CAInfoLayer from './ca/CAInfoLayer';
import CASortLayers from '../helpers/CASortLayers';
import CACBSALayer from './supporting/CACBSALayer';
import CACountyLayer from './supporting/CACountyLayer';
import CAZipCodeLayer from './supporting/CAZipCodeLayer';
// import CADemographicLayers from './supporting/CADemographicLayerMapImage';
import CACrimeLayer from './supporting/CACrimeLayer';
import CAFTrafficLayer from './supporting/CATrafficLayer';
import CXBigBoxLayer from './cxy/CXBigBoxLayer';
import CXChainGroceryLayer from './cxy/CXYChainGroceryLayer';
import CASmallGroceryLayer from './ca/CASmallGroceryLayer';
import CAClosedGroceryLayer from './ca/CAClosedGroceryLayer';
import CXYClubStoreLayer from './cxy/CXYClubStoreLayer';
import CXYPharmacyLayer from './cxy/CXYPharmacyLayer';
import CXYDollarStoreLayer from './cxy/CXYDollarStoreLayer';
import CXYDepartmentStoreLayer from './cxy/CXYDepartmentStoreLayer';
import CXYBankLayer from './cxy/CXYBankLayer';
import CXYFitnessLayer from './cxy/CXYFitnessLayer';
import CXYTheaterLayer from './cxy/CXYTheaterLayer';
import CXYHomeImprovementLayer from './cxy/CXYHomeImprovementLayer';
import CXYOtherLayer from './cxy/CXYOtherLayer';
import CABufferLayer from './supporting/CABufferLayer';
import CAParcelLayer from './supporting/CAParcelLayer';
import PGRLayer from './supporting/PGRLayer';
import CAMHIRendererWidget from '../widgets/CAMHIRendererWidget';
import CADemographicLayers from './supporting/CADemographicsLayer';

export interface ICALayerProps {
    view?: __esri.MapView | __esri.SceneView;
    map?: __esri.Map;
}

const CALayers: React.FC<ICALayerProps> = props => {
    useEffect(() => {
        if (props.map && props.view) {
            const init = async () => {
                if (props.view) {
                    // We need to get a reference to this layer to pass to the mhi renderer widget
                    // Top level await would be nice here then we could just export it out.
                    const demographicsLayer = await CADemographicLayers();
                    const mhiWidget = await CAMHIRendererWidget(props.view, demographicsLayer);
                    props.view.ui.add({component: mhiWidget, position: 'top-left', index: 2});

                    const layers: __esri.Layer[] = [];
                    [
                        await CAInfoLayer(),
                        await PGRLayer(),
                        await CASmallGroceryLayer(),
                        await CAClosedGroceryLayer(),
                        await CXBigBoxLayer(),
                        await CXYPharmacyLayer(),
                        await CXYClubStoreLayer(),
                        await CXChainGroceryLayer(),
                        await CXYBankLayer(),
                        await CXYDollarStoreLayer(),
                        await CXYDepartmentStoreLayer(),
                        await CXYFitnessLayer(),
                        await CXYHomeImprovementLayer(),
                        await CXYTheaterLayer(),
                        await CXYOtherLayer(),
                        await CAParcelLayer(props.view),
                        await CACBSALayer(),
                        await CACountyLayer(),
                        await CACrimeLayer(),
                        demographicsLayer,
                        await CAFTrafficLayer(),
                        await CAZipCodeLayer(),
                        await CABufferLayer()
                    ].forEach(l => {
                        if (l) {
                            layers.push(l);
                        }
                    });

                    props.map?.addMany(layers.sort(CASortLayers));
                }
            };

            init();
        }
    }, [props.map, props.view]);

    return null;
};

export default CALayers;
