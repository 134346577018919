import React from 'react';
import ReactDOM from 'react-dom';
import { WIDGET_BUTTON_CLASS } from '../helpers/CAConstants';
import { DateTime } from 'luxon';

const downloadImage = (filename: string, dataUrl: string) => {
    // the download is handled differently in Microsoft browsers
    // because the download attribute for <a> elements is not supported
    if (!window.navigator.msSaveOrOpenBlob) {
        // in browsers that support the download attribute
        // a link is created and a programmatic click will trigger the download
        const element = document.createElement('a');
        element.setAttribute('href', dataUrl);
        element.setAttribute('download', filename);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    } else {
        // for MS browsers convert dataUrl to Blob
        const byteString = atob(dataUrl.split(',')[1]);
        const mimeString = dataUrl
            .split(',')[0]
            .split(':')[1]
            .split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([ab], { type: mimeString });

        // download file
        window.navigator.msSaveOrOpenBlob(blob, filename);
    }
};

const ScreenshotButton: React.FC<{ view: __esri.MapView | __esri.SceneView }> = props => {
    const onClick = async () => {
        const isLandscape = props.view.height <= props.view.width ? true : false;

        const options = {
            format: 'png',
            height: isLandscape
                ? 1675
                : 2800,
            width: isLandscape
                ? 2800
                : 1675
        } as __esri.MapViewTakeScreenshotOptions;

        const screenshot = await props.view.takeScreenshot(options);
        const fileName = `CA_Print_${DateTime.local().toSeconds()}.png`;
        downloadImage(fileName, screenshot.dataUrl);
    };

    return (
        <div {...WIDGET_BUTTON_CLASS} title="Export PDF" onClick={onClick}>
            <span className="esri-icon-printer" />
        </div>
    );
};

const CAScreenshotWidget = (props: { view: __esri.MapView | __esri.SceneView }) => {
    const node = document.createElement('div');
    ReactDOM.render(<ScreenshotButton view={props.view} />, node);
    return node;
};

export default CAScreenshotWidget;
