import { loadModules } from 'esri-loader';

const CALegendWidget = async (view: __esri.MapView | __esri.SceneView) => {
    type esriModules = [typeof import('esri/widgets/Legend'), typeof import('esri/widgets/Expand')];

    const [Legend, Expand] = await (loadModules(['esri/widgets/Legend', 'esri/widgets/Expand']) as Promise<
        esriModules
    >);

    const legend = new Legend({
        view
    });

    return new Expand({
        content: legend,
        view,
        expandTooltip: 'Legend'
    });
}

export default CALegendWidget;
