import { loadModules } from 'esri-loader';

const CASearchWidget = async (view: __esri.View) => {
    type searchModeules = [
        typeof import('esri/widgets/Search'),
        typeof import('esri/widgets/Search/LocatorSearchSource'),
        typeof import('esri/tasks/Locator'),
        typeof import('esri/widgets/Expand')
    ];

    const [Search, LocatorSearchSource, Locator, Expand] = await (loadModules([
        'esri/widgets/Search',
        'esri/widgets/Search/LocatorSearchSource',
        'esri/tasks/Locator',
        'esri/widgets/Expand'
    ]) as Promise<searchModeules>);

    const search = new Search({
        view,
        includeDefaultSources: false,
        allPlaceholder: 'Search'
    });

    const geocoderSource = new LocatorSearchSource({
        countryCode: 'US',
        name: 'Search Addresses',
        placeholder: 'Search Addresses',
        locator: new Locator({
            url: 'https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer'
        })
    });

    search.sources.add(geocoderSource);

    return new Expand({
        content: search,
        view
    });
};

export default CASearchWidget;
