import { loadModules } from 'esri-loader';
// import { faDrawPolygon, faMapMarked, faRuler, faTrash } from '@fortawesome/pro-light-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useMemo } from 'react';
import * as ReactDOM from 'react-dom';
import { Button } from 'reactstrap';
import format from 'number-format.js';

export const PGRDARKGREEN = [0, 73, 18, 1];
export const PGRGREEN = [78, 183, 74];
export const PGRLIGHTGREEN = [214, 229, 211];

// helpers to emulate the esri widget buttons
export const buttonProps = {
    size: 'sm'
};

interface IMeasureProps {
    Draw: __esri.DrawConstructor;
    geometryEngine: __esri.geometryEngine;
    Graphic: __esri.GraphicConstructor;
    measureLayer: __esri.GraphicsLayer;
    Point: __esri.PointConstructor;
    Polygon: __esri.PolygonConstructor;
    Polyline: __esri.PolylineConstructor;
    SimpleFillSymbol: __esri.SimpleFillSymbolConstructor;
    SimpleLineSymbol: __esri.SimpleLineSymbolConstructor;
    SimpleMarkerSymbol: __esri.SimpleMarkerSymbolConstructor;
    textSymbol: __esri.TextSymbol;
    view: __esri.MapView | __esri.SceneView;
}

const Measure: React.FC<IMeasureProps> = (props) => {
    const distanceUnits = 'feet';
    const areaUnits = 'square-feet';
    const [activeTool, setActiveTool] = useState<'none' | 'distance' | 'area' | 'point'>('none');

    const setCrossHair = (show: boolean) => {
        props.view.container.style.cursor = show ? 'crosshair' : 'auto';
    };

    const lineSymbol = new props.SimpleLineSymbol({
        color: PGRGREEN,
        width: 2.5
    });

    const pointSymbol = new props.SimpleMarkerSymbol({
        color: PGRDARKGREEN,
        style: 'circle',
        outline: lineSymbol,
        size: 8
    });

    const polygonSymbol = new props.SimpleFillSymbol({
        color: [...PGRLIGHTGREEN, 0.5],
        outline: lineSymbol
    });

    const draw = useMemo(() => {
        return new props.Draw({ view: props.view });
    }, [props.Draw, props.view]);

    const drawPolygon = (evt: __esri.PolygonDrawAction, complete?: boolean) => {
        props.view.graphics.removeAll();

        if (evt.vertices.length === 2) {
            const polyline = new props.Polyline({
                paths: [evt.vertices],
                spatialReference: props.view.spatialReference
            });

            const densifiedPolyline = densifyPolyline(polyline);

            const graphic = new props.Graphic({
                geometry: densifiedPolyline,
                symbol: lineSymbol
            });

            props.view.graphics.add(graphic);
        } else if (evt.vertices.length >= 2) {
            const polygon = new props.Polygon({
                rings: [evt.vertices],
                spatialReference: props.view.spatialReference
            });
            const densifiedPolygon = props.geometryEngine.geodesicDensify(polygon, 100, 'miles') as __esri.Polygon;

            const graphic = new props.Graphic({
                geometry: densifiedPolygon || polygon,
                symbol: polygonSymbol
            });

            const area = props.geometryEngine.geodesicArea(polygon, areaUnits);

            if (complete) {
                props.measureLayer.add(graphic);
                props.view.graphics.removeAll();
            } else {
                props.view.graphics.add(graphic);
            }

            labelArea(densifiedPolygon, area, complete);
        }
    };

    const labelArea = (polygon: __esri.Polygon, area: number, complete?: boolean) => {
        const symbol = props.textSymbol.clone();
        const areaSF = Math.abs(area);
        const areaSM = areaSF * 0.092903;
        let areaLabel = `${format('###,###.00', areaSF)} ft² ◆ ${format('###,###.00', areaSM)} m²`;

        if (Math.abs(area) > 43560 * 250) {
            // if >= 250 acres use sqmi and sqkm
            const areaSqMi = areaSF / 27878000;
            const areaSqKm = areaSqMi * 2.58999;
            areaLabel = `${format('###,###.00', areaSqMi)} mi² ◆ ${format('###,###.00', areaSqKm)} km²`;
        } else if (areaSF >= 43560) {
            // if 1 acre or more use ac and ha
            const areaAcres = areaSF / 43560;
            const areaHectares = areaSM / 10000;
            areaLabel = `${format('###,###.00', areaAcres)} ac ◆ ${format('###,###.00', areaHectares)} ha`;
        }

        symbol.text = areaLabel;

        const areaGraphic = new props.Graphic({
            geometry: polygon.centroid,
            symbol
        });

        if (complete) {
            props.measureLayer.add(areaGraphic);
        } else {
            props.view.graphics.add(areaGraphic);
        }
    };

    const handleAreaClick = () => {
        setActiveTool('area');

        const doArea = () => {
            const action = draw.create('polygon');
            setCrossHair(true);

            props.view.focus();

            action.on('vertex-add', (evt: __esri.PolygonDrawAction) => {
                drawPolygon(evt);
            });

            action.on('cursor-update', (evt: __esri.PolygonDrawAction) => {
                drawPolygon(evt);
            });

            action.on('vertex-remove', (evt: __esri.PolygonDrawAction) => {
                drawPolygon(evt);
            });

            action.on('draw-complete', (evt: __esri.PolygonDrawAction) => {
                drawPolygon(evt, true);
                setCrossHair(false);
                draw.reset();
                setActiveTool('none');
            });
        };

        if (draw.activeAction) {
            setCrossHair(false);
            draw.reset();
            props.view.graphics.removeAll();
            doArea();
        } else {
            doArea();
        }
    };

    const labelDistance = (geometry: __esri.Polyline | __esri.Polygon, distance: number, complete?: boolean) => {
        const symbol = props.textSymbol.clone();
        symbol.yoffset = 6;

        let distanceLabel = `${format('###,###.00', distance)}' ◆ ${format('###,###.00', distance * 0.3048)} m`;

        if (distance >= 10560) {
            // label in miles for long distances
            const miles = Math.abs(distance / 5280);
            distanceLabel = `${format('###,###.00', miles)} mi ◆ ${format('###,###.00', miles * 1.60934)} km`;
        }

        symbol.text = distanceLabel;

        // If single segment polyline, align the label with the segment. otherwise, set it to the center of the extent
        if (geometry.type === 'polyline' && geometry.paths.length === 1 && geometry.paths[0].length === 2) {
            // single segment polyline
            const x0 = geometry.paths[0][0][0] as number;
            const x1 = geometry.paths[0][1][0];

            const y0 = geometry.paths[0][0][1];
            const y1 = geometry.paths[0][1][1];

            if (Math.abs(y1 - y0) > 0) {
                // if user clicks and doesnt move mouse then a 0 length pline results which gives a divide by 0 error
                const angleRadian = Math.atan((x1 - x0) / (y1 - y0));
                const angleDeg = (angleRadian * 180) / Math.PI;

                if (!isNaN(angleDeg) && angleDeg < 0) {
                    symbol.angle = angleDeg + 90;
                } else if (!isNaN(angleDeg) && angleDeg > 0) {
                    symbol.angle = angleDeg - 90;
                } else {
                    // Horizontal
                    symbol.angle = 0;
                }
            } else {
                symbol.angle = 0;
            }
        }

        const distanceGraphic = new props.Graphic({
            geometry: geometry.extent.center,
            symbol: symbol
        });

        if (complete) {
            props.measureLayer.add(distanceGraphic);
        } else {
            props.view.graphics.add(distanceGraphic);
        }
    };

    const densifyPolyline = (polyline: __esri.Polyline) => {
        const simplifiedPolyline = props.geometryEngine.simplify(polyline) as __esri.Polyline;
        return props.geometryEngine.geodesicDensify(simplifiedPolyline, 100, 'miles') as __esri.Polyline;
    };

    const drawPolyline = (evt: __esri.PolylineDrawAction, complete?: boolean) => {
        props.view.graphics.removeAll();

        const polyline = new props.Polyline({
            paths: [evt.vertices],
            spatialReference: props.view.spatialReference
        });

        const distance = props.geometryEngine.geodesicLength(polyline, distanceUnits);

        if (distance > 0) {
            const densifiedPolyline = densifyPolyline(polyline);
            const graphic = new props.Graphic({
                geometry: densifiedPolyline,
                symbol: lineSymbol
            });

            if (complete) {
                props.measureLayer.add(graphic);
                props.view.graphics.removeAll();
            } else {
                props.view.graphics.add(graphic);
            }

            labelDistance(densifiedPolyline, distance, complete);
        }
    };

    const handleDistanceClick = () => {
        setActiveTool('distance');

        const doDistance = () => {
            const action = draw.create('polyline');
            setCrossHair(true);

            props.view.focus();

            action.on('vertex-add', (evt: __esri.PolylineDrawAction) => {
                drawPolyline(evt);
            });

            action.on('cursor-update', (evt: __esri.PolylineDrawAction) => {
                drawPolyline(evt);
            });

            action.on('vertex-remove', (evt: __esri.PolylineDrawAction) => {
                drawPolyline(evt);
            });

            action.on('draw-complete', (evt: __esri.PolylineDrawAction) => {
                drawPolyline(evt, true);
                setCrossHair(false);
                draw.reset();
                setActiveTool('none');
            });
        };

        if (draw.activeAction) {
            setCrossHair(false);
            draw.reset();
            props.view.graphics.removeAll();
            doDistance();
        } else {
            doDistance();
        }
    };

    const handlePointClick = () => {
        setActiveTool('point');

        const doPoint = () => {
            const action = draw.create('point');
            setCrossHair(true);

            props.view.focus();

            action.on('cursor-update', (evt: __esri.PointDrawAction) => {
                drawPoint(evt);
            });

            action.on('draw-complete', (evt: __esri.PointDrawAction) => {
                drawPoint(evt, true);
                setCrossHair(false);
                draw.reset();
                setActiveTool('none');
            });
        };

        if (draw.activeAction) {
            setCrossHair(false);
            draw.reset();
            props.view.graphics.removeAll();
            doPoint();
        } else {
            doPoint();
        }
    };

    const labelPoint = (point: __esri.Point, complete?: boolean) => {
        const symbol = props.textSymbol.clone();

        const lat = format('###,###.0000', point.latitude);
        const lng = format('###,###.0000', point.longitude);

        symbol.text = `${lng}° ${lat}°`;
        symbol.xoffset = 0;
        symbol.yoffset = 10;

        const graphic = new props.Graphic({
            symbol,
            geometry: point
        });

        if (complete) {
            props.measureLayer.add(graphic);
        } else {
            props.view.graphics.add(graphic);
        }
    };

    const drawPoint = (evt: __esri.PointDrawAction, complete?: boolean) => {
        props.view.graphics.removeAll();

        const point = new props.Point({
            x: evt.coordinates[0],
            y: evt.coordinates[1],
            spatialReference: props.view.spatialReference
        });

        const graphic = new props.Graphic({
            geometry: point,
            symbol: pointSymbol
        });

        if (complete) {
            props.measureLayer.add(graphic);
            props.view.graphics.removeAll();
        } else {
            props.view.graphics.add(graphic);
        }

        labelPoint(point, complete);
    };

    const handleClearMeasurements = () => {
        draw.reset();
        props.view.graphics.removeAll();
        props.measureLayer.removeAll();
        setCrossHair(false);
        setActiveTool('none');
    };

    return (
        <div className="d-flex justify-countent-around">
            <Button
                aria-label="Measure Distance"
                title="Measure Distance"
                className="rounded-0"
                onClick={handleDistanceClick}
                color={activeTool === 'distance' ? 'primary' : 'light'}
                {...buttonProps}
            >
                <div className="esri-icon-measure-line" />
            </Button>
            <Button
                aria-label="Measure Area"
                title="Measure Area"
                className="rounded-0"
                {...buttonProps}
                color={activeTool === 'area' ? 'primary' : 'light'}
                onClick={handleAreaClick}
            >
                <div className="esri-icon-measure-area" />
            </Button>
            <Button
                aria-label="Mark Lat/Lng"
                title="Mark Lat/Lng"
                className="rounded-0"
                {...buttonProps}
                color={activeTool === 'point' ? 'primary' : 'light'}
                onClick={handlePointClick}
            >
                <div className="esri-icon-applications" />
            </Button>
            <Button
                aria-label="Clear Measurements"
                title="Clear Measurements"
                className="rounded-0"
                onClick={handleClearMeasurements}
                color="light"
                {...buttonProps}
            >
                <div className="esri-icon-erase" />
            </Button>
        </div>
    );
};

const CAMeasureWidget = async (view: __esri.MapView | __esri.SceneView) => {
    const measureNode = document.createElement('div');
    measureNode.classList.add('bg-white');

    type measureModules = [
        typeof import('esri/views/draw/Draw'),
        typeof import('esri/geometry/geometryEngine'),
        typeof import('esri/Graphic'),
        typeof import('esri/layers/GraphicsLayer'),
        typeof import('esri/geometry/Point'),
        typeof import('esri/geometry/Polygon'),
        typeof import('esri/geometry/Polyline'),
        typeof import('esri/symbols/SimpleFillSymbol'),
        typeof import('esri/symbols/SimpleLineSymbol'),
        typeof import('esri/symbols/SimpleMarkerSymbol'),
        typeof import('esri/symbols/TextSymbol'),
        typeof import('esri/widgets/Expand')
    ];

    const [
        Draw,
        geometryEngine,
        Graphic,
        GraphicsLayer,
        Point,
        Polygon,
        Polyline,
        SimpleFillSymbol,
        SimpleLineSymbol,
        SimpleMarkerSymbol,
        TextSymbol,
        Expand
    ] = await (loadModules([
        'esri/views/draw/Draw',
        'esri/geometry/geometryEngine',
        'esri/Graphic',
        'esri/layers/GraphicsLayer',
        'esri/geometry/Point',
        'esri/geometry/Polygon',
        'esri/geometry/Polyline',
        'esri/symbols/SimpleFillSymbol',
        'esri/symbols/SimpleLineSymbol',
        'esri/symbols/SimpleMarkerSymbol',
        'esri/symbols/TextSymbol',
        'esri/widgets/Expand'
    ]) as Promise<measureModules>);

    const measureLayer = new GraphicsLayer({
        listMode: 'hide'
    });

    view.map.add(measureLayer);

    const textSymbol = new TextSymbol({
        color: PGRLIGHTGREEN,
        haloColor: PGRDARKGREEN,
        haloSize: 2,
        xoffset: 0,
        yoffset: 0,
        font: {
            size: 12,
            family: 'sans-serif',
            weight: 'bold'
        }
    });

    ReactDOM.render(
        <Measure
            Draw={Draw}
            geometryEngine={geometryEngine}
            Graphic={Graphic}
            measureLayer={measureLayer}
            Point={Point}
            Polygon={Polygon}
            Polyline={Polyline}
            SimpleFillSymbol={SimpleFillSymbol}
            SimpleLineSymbol={SimpleLineSymbol}
            SimpleMarkerSymbol={SimpleMarkerSymbol}
            textSymbol={textSymbol}
            view={view}
        />,
        measureNode
    );

    return new Expand({
        view,
        content: measureNode,
        expandIconClass: 'esri-icon-measure'
    });

};

export default CAMeasureWidget;
