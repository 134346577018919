const openGoogleMap = (lat: number, lng: number) => {
    window.open(`https://www.google.com/maps/search/?api=1&query=${lat},${lng}`);
};

const CAOpenPointInGoogle = (geometry: __esri.Geometry) => {
    let lat: number;
    let lng: number;

    if (geometry.type === 'point') {
        lat = (geometry as __esri.Point).latitude;
        lng = (geometry as __esri.Point).longitude;
        openGoogleMap(lat, lng);
    } else if (geometry.type === 'polygon') {
        const centroid = (geometry as __esri.Polygon).centroid;
        lat = centroid.latitude;
        lng = centroid.longitude;
        openGoogleMap(lat, lng);
    } else {
        console.warn('Could not get a valid lat and/or lng to open in google maps.');
    }
};

export default CAOpenPointInGoogle;
