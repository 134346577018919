import { loadModules } from 'esri-loader';
import CABufferPopup from '../popups/CABufferPopup';
import { CA_COLORS } from './CAConstants';
import { bufferLayerTitle } from '../layers/supporting/CABufferLayer';
import { CATextSymbol } from './CALayerLabels';
import { CAEnrichmentVariables } from '../CAEnrichmentVariables';

const CABufferSymbol = async () => {
    type esriModules = [typeof import('esri/symbols/SimpleLineSymbol'), typeof import('esri/symbols/SimpleFillSymbol')];

    const [SimpleLineSymbol, SimpleFillSymbol] = await (loadModules([
        'esri/symbols/SimpleLineSymbol',
        'esri/symbols/SimpleFillSymbol'
    ]) as Promise<esriModules>);

    const outline = new SimpleLineSymbol({
        width: 3,
        color: CA_COLORS.Primary
    });

    return new SimpleFillSymbol({
        color: [255, 255, 255, 0],
        outline
    });
};

/**
 * Returns a graphic with apprpriate symbolgy for a buffer polygon
 */
export const CAGetBufferGraphic = async () => {
    type esriModules = [typeof import('esri/Graphic')];

    const [Graphic] = await (loadModules(['esri/Graphic']) as Promise<esriModules>);
    const popupTemplate = await CABufferPopup();

    return new Graphic({
        popupTemplate,
        symbol: await CABufferSymbol()
    });
};

/**
 * Returns a point graphic for use in marking buffered points
 * @param point
 */
const CABufferPointGraphic = async (point: __esri.Point) => {
    type esriModules = [typeof import('esri/Graphic'), typeof import('esri/symbols/SimpleMarkerSymbol')];

    const [Graphic, SimpleMarkerSymbol] = await (loadModules([
        'esri/Graphic',
        'esri/symbols/SimpleMarkerSymbol'
    ]) as Promise<esriModules>);

    const symbol = new SimpleMarkerSymbol({
        style: 'x',
        size: 8,
        color: CA_COLORS.Primary
    });

    return new Graphic({
        symbol,
        geometry: point
    });
};

/**
 * Enriches a graphic
 * @param graphic
 * @param returnGeometry
 */
const CAEnrichGraphic = async (graphic: __esri.Graphic, returnGeometry?: boolean) => {
    type esriModules = [typeof import('esri/request')];

    const [esriRequest] = await (loadModules(['esri/request']) as Promise<esriModules>);

    const url = 'https://geoenrich.arcgis.com/arcgis/rest/services/World/geoenrichmentserver/GeoEnrichment/enrich';
    const studyArea = {
        geometry: graphic.geometry,
        attributes: graphic.attributes
    };

    const req = esriRequest(url, {
        query: {
            f: 'json',
            studyAreas: JSON.stringify([studyArea]),
            analysisVariables: [CAEnrichmentVariables],
            // analysisVariables: ['Health.MEDHINC_CY'],
            returnGeometry: returnGeometry ? returnGeometry : false
        }
    });

    // req.then(result => {
    //     console.log(result.data?.results?.[0].value.FeatureSet[0]?.features[0].attributes);
    // });

    return req;
};

/**
 * Creates a geodesic ring buffer polygon based off an input location
 * @param point
 * @param distance
 */
const CAGetRingBufferGraphic = async (point: __esri.Point, distance: number) => {
    type esriModules = [typeof import('esri/geometry/geometryEngine')];

    const [geometryEngine] = await (loadModules(['esri/geometry/geometryEngine']) as Promise<esriModules>);

    const buffer = geometryEngine.geodesicBuffer(point, distance, 'miles') as __esri.Polygon;

    const graphic = await CAGetBufferGraphic();
    graphic.geometry = buffer;
    graphic.attributes = {
        NAME: 'Ring Buffer',
        bufferDesc: `${distance}-Mile Ring`
    };

    return graphic;
};

const CAGetDriveTimeGraphic = async (point: __esri.Point, distance: number) => {
    type esriModules = [typeof import('esri/request'), typeof import('esri/geometry/Polygon')];

    const [esriRequest, Polygon] = await (loadModules(['esri/request', 'esri/geometry/Polygon']) as Promise<
        esriModules
    >);
    const url =
        'https://route.arcgis.com/arcgis/rest/services/World/ServiceAreas/NAServer/ServiceArea_World/solveServiceArea';

    return esriRequest(url, {
        query: {
            f: 'json',
            defaultBreaks: [distance],
            facilities: `${point.longitude}, ${point.latitude}`
        }
    })
        .then(async r => {
            const feature = r.data.saPolygons.features[0];
            const graphic = await CAGetBufferGraphic();
            graphic.geometry = Polygon.fromJSON(feature.geometry);
            graphic.attributes = {
                NAME: 'Drivetime Buffer',
                bufferDesc: `${feature.attributes.ToBreak}-Minute Drive`
            };
            return graphic;
        })
        .catch((err: Error) => {
            throw err;
        });
};

export const CADriveBuffer = async (
    point: __esri.Point,
    distances: number[],
    view: __esri.MapView | __esri.SceneView
) => {
    const bufferGraphics = distances.map(async distance => {
        return await CAGetDriveTimeGraphic(point, distance);
    });

    if (bufferGraphics && bufferGraphics.length) {
        return Promise.all(bufferGraphics)
            .then(async graphics => {
                return await CAEnrichBuffers({
                    graphics,
                    mapPoint: point,
                    bufferValues: distances,
                    view
                });
            })
            .catch(err => {
                console.error(err);
                return false;
            });
    } else {
        return false;
    }
};

/**
 * Parent function for creating and enriching geodesic ring buffers given a location
 * and an array of distances.
 * @param point
 * @param distances
 * @param view
 */
export const CARingBuffer = async (
    point: __esri.Point,
    distances: number[],
    view: __esri.MapView | __esri.SceneView
) => {
    const bufferGraphics = distances.map(async distance => {
        return await CAGetRingBufferGraphic(point, distance);
    });

    if (bufferGraphics && bufferGraphics.length) {
        return Promise.all(bufferGraphics)
            .then(async graphics => {
                return await CAEnrichBuffers({
                    graphics,
                    mapPoint: point,
                    bufferValues: distances,
                    view
                });
            })
            .catch(err => {
                console.error(err);
                return false;
            });
    } else {
        return false;
    }
};

export const customBufferValueName = 'Custom Buffer';
/**
 * Accepts a graphic with polygon geometry and enriches it.
 * @param view
 * @param bufferGraphic
 */
export const CACustomBuffer = async (view: __esri.MapView | __esri.SceneView, bufferGraphic: __esri.Graphic) => {
    bufferGraphic.attributes = {
        NAME: customBufferValueName,
        bufferValues: [customBufferValueName],
        bufferDesc: 'Custom Buffer'
    };
    bufferGraphic.symbol = await CABufferSymbol();

    return await CAEnrichBuffers({
        graphics: [bufferGraphic],
        mapPoint: (bufferGraphic.geometry as __esri.Polygon).centroid,
        view,
        bufferValues: [customBufferValueName]
    });
};

interface ICACompleteBufferProps {
    view: __esri.MapView | __esri.SceneView;
    mapPoint: __esri.Point;
    graphics: __esri.Graphic[];
    bufferValues: Array<string | number>;
}

/**
 * Enriches and array of graphics then adds them to the bufferLayer.
 * Once added, closes any popups that are open and presents a new
 * popup with the enriched graphics as the selected features
 * @param props
 */
const CAEnrichBuffers = async (props: ICACompleteBufferProps) => {
    const bufferLayer = props.view.map.layers.find(l => l.title === bufferLayerTitle) as __esri.GraphicsLayer;
    props.view.graphics.removeAll();

    const markerGraphic = await CABufferPointGraphic(props.mapPoint);
    bufferLayer.graphics.add(markerGraphic);
    bufferLayer.graphics.addMany(props.graphics.reverse());

    let maxExtent = props.graphics[0].geometry.extent.clone();
    const enrichPromises: Promise<__esri.RequestResponse>[] = [];

    props.graphics.forEach(g => {
        g.attributes.bufferValues = props.bufferValues;
        enrichPromises.push(CAEnrichGraphic(g));

        if (g.geometry.extent.width > maxExtent.width) {
            maxExtent = g.geometry.extent.clone();
        }
    });

    try {
        const results = await Promise.all(enrichPromises);

        const enrichmentResults = results.map(r => {
            return r.data.results[0].value.FeatureSet[0].features[0].attributes;
        });

        enrichmentResults.sort((a: any, b: any) => {
            const aDist = parseFloat(a.bufferDesc.split('-')[0]);
            const bDist = parseFloat(b.bufferDesc.split('-')[0]);
            if (aDist > bDist) {
                return 1;
            } else if (aDist < bDist) {
                return -1;
            }
            return 0;
        });

        props.graphics.forEach(g => {
            g.attributes.lat = props.mapPoint.latitude;
            g.attributes.lng = props.mapPoint.longitude;
            g.attributes.enrichResults = enrichmentResults;
        });

        addLabels({
            graphics: props.graphics,
            layer: bufferLayer
        });

        props.view.popup.close();
        props.view.popup.clear();
        props.view.popup.open({
            features: props.graphics
        });

        props.view.extent = maxExtent.expand(1.35);

        return true;
    } catch (err) {
        console.error(err);
        return false;
    }
};

/**
 * Accepts an array of polygon graphics and a GraphicsLayer and labels them at their
 * northern-most point with the value in the `bufferDesc` property of graphic.attribute
 * @param props
 */
const addLabels = async (props: { graphics: __esri.Graphic[]; layer: __esri.GraphicsLayer }) => {
    type esriModules = [typeof import('esri/Graphic'), typeof import('esri/geometry/Point')];
    const [Graphic, Point] = await (loadModules(['esri/Graphic', 'esri/geometry/Point']) as Promise<esriModules>);
    const textSymbol = (await CATextSymbol()).clone();
    textSymbol.yoffset = 6;

    props.graphics.forEach(g => {
        let northPoint = [0, 0];

        if (g.geometry.type === 'polygon') {
            (g.geometry as __esri.Polygon).rings[0].forEach(r => {
                if (r[1] > northPoint[1]) {
                    northPoint = r;
                }
            });

            const symbol = textSymbol.clone();
            symbol.text = g.attributes.bufferDesc;

            const label = new Graphic({
                symbol,
                geometry: new Point({
                    x: northPoint[0],
                    y: northPoint[1],
                    spatialReference: g.geometry.spatialReference
                })
            });

            props.layer.add(label);
        }
    });
};

const CAGetStudyAreasForReport = (point: __esri.Point): any => {
    const geometry = {
        x: point.longitude,
        y: point.latitude
    };

    const studyAreas = [
        {
            geometry: geometry
        }
    ];

    return studyAreas;
};

interface ICAReportProps {
    title?: string;
    point: __esri.Point;
    distances: number[];
}

const CADandIReportFields = {
    title: 'Demographic and Income Profile',
    subtitle: 'Produced by Consolidated Affiliates'
};

const CACommunityProfileReportFields = {
    title: 'Community Profile',
    subtitle: 'Produced by Consolidated Affiliates'
};

const CACommunityProfileReportName = 'community_profile.pdf';

const CAEsriReport = async (query: any) => {
    type esriModules = [typeof import('esri/request')];

    const [esriRequest] = await (loadModules(['esri/request']) as Promise<esriModules>);

    const url =
        'https://geoenrich.arcgis.com/arcgis/rest/services/World/geoenrichmentserver/GeoEnrichment/CreateReport';

    return esriRequest(url, {
        responseType: 'blob',
        query: query
    });
};

export const CADriveReport = async (props: ICAReportProps) => {
    const studyAreasOptions = {
        areaType: 'NetworkServiceArea',
        bufferUnits: 'esriDriveTimeUnitsMinutes',
        bufferRadii: props.distances
    };

    if (props.title && props.title !== '') {
        CADandIReportFields.title = props.title;
    }

    const standardReportQuery = {
        f: 'bin',
        format: 'pdf',
        studyAreas: JSON.stringify(CAGetStudyAreasForReport(props.point)),
        studyAreasOptions: JSON.stringify(studyAreasOptions),
        report: 'dandi',
        reportFields: JSON.stringify(CADandIReportFields)
    };

    const communityProfileQuery = {
        f: 'bin',
        format: 'pdf',
        studyAreas: JSON.stringify(CAGetStudyAreasForReport(props.point)),
        studyAreasOptions: JSON.stringify(studyAreasOptions),
        report: 'community_profile',
        reportFields: JSON.stringify(CACommunityProfileReportFields)
    };

    try {
        const standardReport = await CAEsriReport(standardReportQuery);
        CADownloadReport(standardReport);

        const communityProfileReport = await CAEsriReport(communityProfileQuery);
        CADownloadReport(communityProfileReport, CACommunityProfileReportName);

        return true;
    } catch (error) {
        console.error(error);
        return false;
    }
};

export const CARingReport = async (props: ICAReportProps) => {
    const studyAreasOptions = {
        areaType: 'RingBuffer',
        bufferUnits: 'esriMiles',
        bufferRadii: props.distances
    };

    if (props.title && props.title !== '') {
        CADandIReportFields.title = props.title;
    }

    const standardReportQuery = {
        f: 'bin',
        format: 'pdf',
        studyAreas: JSON.stringify(CAGetStudyAreasForReport(props.point)),
        studyAreasOptions: JSON.stringify(studyAreasOptions),
        report: 'dandi',
        reportFields: JSON.stringify(CADandIReportFields)
    };

    const communityProfileQuery = {
        f: 'bin',
        format: 'pdf',
        studyAreas: JSON.stringify(CAGetStudyAreasForReport(props.point)),
        studyAreasOptions: JSON.stringify(studyAreasOptions),
        report: 'community_profile',
        reportFields: JSON.stringify(CACommunityProfileReportFields)
    };

    try {
        const standardReport = await CAEsriReport(standardReportQuery);
        CADownloadReport(standardReport);

        const communityProfileReport = await CAEsriReport(communityProfileQuery);
        CADownloadReport(communityProfileReport, CACommunityProfileReportName);

        return true;
    } catch (error) {
        console.error(error);
        return false;
    }
};

interface ICACustomReportProps {
    graphics: __esri.Graphic[];
    title?: string;
}

export const CACustomReport = async (props: ICACustomReportProps) => {
    const studyAreas = props.graphics.map(g => {
        return {
            geometry: g.geometry,
            attributes: g.attributes
        };
    });

    const repFields = {
        title: 'Custom Area Demographic & Income Profile',
        latitude: (props.graphics[0].geometry as __esri.Polygon).centroid.latitude,
        longitude: (props.graphics[0].geometry as __esri.Polygon).centroid.longitude
    };

    if (props.title && props.title !== '') {
        repFields.title = props.title;
    }

    const standardReportQuery = {
        f: 'bin',
        format: 'pdf',
        studyAreas: JSON.stringify(studyAreas.reverse()),
        report: 'dandi',
        reportFields: JSON.stringify({ ...repFields, title: `Demo. & Inc. Profile ${props.title ?? ''}` })
    };

    const communityProfileQuery = {
        f: 'bin',
        format: 'pdf',
        studyAreas: JSON.stringify(studyAreas.reverse()),
        report: 'community_profile',
        reportFields: JSON.stringify({ ...repFields, title: `Community Profile ${props.title ?? ''}` })
    };

    try {
        const standardReport = await CAEsriReport(standardReportQuery);
        CADownloadReport(standardReport);

        const communityProfileReport = await CAEsriReport(communityProfileQuery);
        CADownloadReport(communityProfileReport, CACommunityProfileReportName);

        return true;
    } catch (error) {
        console.error(error);
        return false;
    }
};

const CADownloadReport = (r: any, name?: string) => {
    const data = r.data; // https://stackoverflow.com/a/23451803
    const url = window.URL.createObjectURL(data);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display:none;');
    a.href = url;
    a.download = name ?? 'Esri Report.pdf';
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
};
