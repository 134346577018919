import { loadModules } from 'esri-loader';
import React from 'react';
import ReactDOM from 'react-dom';
import { ISWSParcelAttributes } from '../layers/supporting/CAParcelLayer';
import format from 'number-format.js'; // https://github.com/Mottie/javascript-number-formatter


const CAParcelPopup = async (view: __esri.MapView | __esri.SceneView) => {
    type popupModules = [typeof import('esri/PopupTemplate')];
    const [PopupTemplate] = await (loadModules(['esri/PopupTemplate']) as Promise<popupModules>);

    return new PopupTemplate({
        title: 'Parcel {apn}',
        content: (feature: __esri.Feature) => {
            const node = document.createElement('div');
            const atts = feature.graphic.attributes as ISWSParcelAttributes;
            const address = atts.stdAddr;

            const content = (
                <React.Fragment>
                    {atts.owner ? <div>{atts.owner}</div> : null}
                    {address ? <div>{address}</div> : null}
                    <div>
                        {atts.city}, {atts.state} {atts.zip}
                    </div>
                    <div>{format('#,##0.00', atts.area_ac)} Acres ±</div>
                </React.Fragment>
            );

            ReactDOM.render(content, node);

            return node;
        }
    });
};

export default CAParcelPopup;
