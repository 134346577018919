import { loadModules } from 'esri-loader';
import CAParcelPopup from '../../popups/CAParcelPopup';
import { getSupportingLayerId } from '../../helpers/CALayerID';

/** SWS error response */
interface ISWSErrors {
    code: number;
    message: string;
    documentationUrl?: string;
}

/** SWS spatial record page info */
interface ISWSPageInfo {
    actualPageSize: number;
    length: number;
    page: number;
    pageSize: number;
}

/** SWS spatial record response */
export interface ISWSRecordResponse {
    errors?: ISWSErrors[];
    pageInfo?: ISWSPageInfo;
    parcels?: ISWSParcelAttributes[];
}

/**
 * SWS spatial record attributes. See https://www.corelogic.com/downloadable-docs/cl-spatialrecord-oil-and-gas-data-fields-insert.pdf
 * Note that note all attributes we receive are listed in that document.
 */
export interface ISWSParcelAttributes {
    zip: string;
    owner: string;
    city: string;
    stdState: string;
    stdAddr: string;
    stdCity: string;
    typeCode: string;
    parcelId: string;
    stdZip: string;
    countyCode: string;
    stdPlus: string;
    geometry: string;
    stateCode: string;
    state: string;
    addr: string;
    apn2: string;
    apn: string;
    area_ac: number;
    area_hectares: number;

    [key: string]: string | number | undefined;
}

const getSWSKey = () => {
    // let url = 'https://projects.beitzanddaigh.com/ca2/parcels.php';
    let url = 'https://ca.works/parcels.php';

    return fetch(url)
        .then(r => r.json())
        .then(response => {
            if ('authKey' in response) {
                return response.authKey;
            } else {
                throw new Error('authKey not found in response.');
            }
        })
        .catch(err => {
            throw err;
        });
};

interface IBSLAParcelClickProps {
    view: __esri.MapView | __esri.SceneView;
    authKey: String;
}

const setupLayer = async (props: IBSLAParcelClickProps) => {
    type layerModules = [typeof import('esri/layers/WebTileLayer')];

    const [WebTileLayer] = await (loadModules(['esri/layers/WebTileLayer']) as Promise<layerModules>);
    const title = 'Parcels';

    const parcelLayer = new WebTileLayer({
        title,
        copyright: 'Parcel Data Via CoreLogic',
        id: getSupportingLayerId(title),
        visible: false,
        minScale: 5000,
        urlTemplate: `https://sws.corelogic.com/api/v3.0.0/tile/gmap?x={col}&y={row}&zoom={level}&layers=fass%3Aparcel&styles=parcelpolygonorange&authKey=${props.authKey}`
    });

    let parcelGraphic: __esri.Graphic;

    // Setup Click listener
    props.view.on('click', async evt => {
        if (parcelLayer.visible && props.view.scale <= parcelLayer.minScale) {
            const parcelReponse = await doQuery({ mapPoint: evt.mapPoint, authKey: props.authKey });
            if (parcelReponse.parcels && parcelReponse.parcels.length > 0) {
                parcelGraphic = await getParcelGraphic(parcelReponse.parcels[0]);
                parcelGraphic.popupTemplate = await CAParcelPopup(props.view);

                // This is still glitchy. Doesn't reliably work with multiple layers, especially e.g. Deomgraphic layers
                if (props.view.popup.featureCount > 0) {
                    props.view.popup.features = [...props.view.popup.features, parcelGraphic];
                } else {
                    props.view.popup.clear();
                    props.view.popup.open({
                        features: [...props.view.popup.features, parcelGraphic],
                        location: evt.mapPoint
                    });
                }
            }
        }
    });

    props.view.popup.watch('selectedFeature', () => {
        if (props.view.popup.selectedFeature && 'parcelId' in props.view.popup.selectedFeature.attributes) {
            props.view.graphics.add(parcelGraphic);
        } else {
            props.view.graphics.remove(parcelGraphic);
        }
    });

    props.view.popup.watch('visible', () => {
        if (!props.view.popup.visible) {
            props.view.graphics.remove(parcelGraphic);
        }
    });

    return parcelLayer;
};

interface ICAParcelQuery {
    mapPoint: __esri.Point;
    authKey: String;
}

/**
 * Formats the SWS geometry string into a valid set of rings to use for creating an ```__esri.Polygon```
 * @param geoString
 */
const formatGeometry = (geoString: string) => {
    let formatted = geoString.replace('POLYGON ', '');
    formatted = formatted.replace(/,/g, '],[');
    formatted = formatted.replace(/\[ /g, '[');
    formatted = formatted.replace(/\s/g, ',');
    formatted = formatted.replace(/\(/g, '[');
    formatted = formatted.replace(/\)/g, ']');

    return JSON.parse(formatted);
};

const getParcelGraphic = async (attributes: ISWSParcelAttributes) => {
    type esriModules = [
        typeof import('esri/Graphic'),
        typeof import('esri/geometry/Polygon'),
        typeof import('esri/geometry/geometryEngine'),
        typeof import('esri/symbols/SimpleFillSymbol'),
        typeof import('esri/symbols/SimpleLineSymbol')
    ];
    const [Graphic, Polygon, geometryEngine, SimpleFillSymbol, SimpleLineSymbol] = await (loadModules([
        'esri/Graphic',
        'esri/geometry/Polygon',
        'esri/geometry/geometryEngine',
        'esri/symbols/SimpleFillSymbol',
        'esri/symbols/SimpleLineSymbol'
    ]) as Promise<esriModules>);

    const rings = formatGeometry(attributes.geometry);

    const polygon = new Polygon({
        rings: rings
    });

    delete attributes.geometry; // don't need this in the attributes after we generate the polygon

    attributes.area_ac = Math.abs(geometryEngine.geodesicArea(polygon, 'acres'));
    attributes.area_hectares = Math.abs(geometryEngine.geodesicArea(polygon, 'hectares'));

    return new Graphic({
        geometry: polygon,
        attributes,
        symbol: new SimpleFillSymbol({
            color: [0, 255, 255, 0.25],
            outline: new SimpleLineSymbol({
                color: [0, 255, 255]
            })
        })
    });
};

const doQuery = (props: ICAParcelQuery): Promise<ISWSRecordResponse> => {
    const lat = props.mapPoint.latitude;
    const lng = props.mapPoint.longitude;
    const url = `https://sws.corelogic.com/api/v3.0.0/parcels?lat=${lat}&lon=${lng}&pageNumber=1&pageSize=4&authKey=${props.authKey}`;

    return fetch(url)
        .then(r => r.json())
        .then((response: ISWSRecordResponse) => {
            if ('errors' in response) {
                const errorMessages = response.errors?.map((e: ISWSErrors) => e.message);
                throw new Error(errorMessages?.join(', '));
            }

            if ('parcels' in response) {
                return response;
            }
        })
        .catch(err => {
            console.error(err);
            return err;
        });
};

const CAParcelLayer = async (view: __esri.MapView | __esri.SceneView) => {
    try {
        const authKey = await getSWSKey();
        const layer = await setupLayer({ authKey, view: view });
        return layer;
    } catch (error) {
        console.warn(error);
    }
};

export default CAParcelLayer;
