import { loadModules } from 'esri-loader';
import CAInfoQuery from '../helpers/CAInfoQuery';
import { CAInfoContent } from './CAInfoPopup';
import { CAViewOnGooglePopupAction, CANearbyStatsPopupAction } from './CAPoupActions';

const CXYPopup = async () => {
    type esriModules = [typeof import('esri/PopupTemplate')];

    const [PopupTemplate] = await (loadModules(['esri/PopupTemplate']) as Promise<esriModules>);

    return new PopupTemplate({
        outFields: ['*'],
        title: (feature: __esri.Feature) => {
            const atts = feature.graphic.attributes;
            return `${atts.Label}${atts.StoreNumber ? ' - ' + atts.StoreNumber : ''}`;
        },
        content: (feature: __esri.Feature) => {
            const atts = feature.graphic.attributes;

            return CAInfoQuery(feature.graphic)
                .then(r => {
                    if (r.features.length) {
                        return CAInfoContent(r.features[0]);
                    } else {
                        const addressValues = [
                            atts.STREET_ADD || atts.Address,
                            `${atts.CITY || atts.City}, ${atts.STATE || atts.State}  ${atts.ZIP_CODE ||
                                atts.PostalCode}`
                        ];
                        return addressValues.join('<br>');
                    }
                })
                .catch(err => {
                    console.error(err);
                });
        },
        actions: [await CAViewOnGooglePopupAction(), await CANearbyStatsPopupAction()]
    });
};

export default CXYPopup;
