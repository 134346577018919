import { loadModules } from 'esri-loader';
import { CALayerLabels } from '../../helpers/CALayerLabels';
import CAInfoPopup from '../../popups/CAInfoPopup';
import { getCALayerId } from '../../helpers/CALayerID';

export const CAInfoURL = 'https://services8.arcgis.com/osXWqrTKrwWBat15/arcgis/rest/services/CA_Info/FeatureServer/0';

const CAInfoLayer = async () => {
    type layerModules = [typeof import('esri/layers/FeatureLayer')];

    const [FeatureLayer] = await (loadModules(['esri/layers/FeatureLayer']) as Promise<layerModules>);

    const title = 'CA Info';

    return new FeatureLayer({
        title,
        id: getCALayerId(title),
        visible: true,
        url: CAInfoURL,
        labelsVisible: false,
        labelingInfo: [await CALayerLabels()],
        popupTemplate: await CAInfoPopup(),
        legendEnabled: false
    });
};

export default CAInfoLayer;
