import { loadModules } from 'esri-loader';
import { SUPPORT_LAYER_ID_PREFIX } from '../helpers/CAConstants';

const CALayerListSupporting = async (view: __esri.MapView | __esri.SceneView) => {
    type layerModules = [typeof import('esri/widgets/LayerList'), typeof import('esri/widgets/Expand')];

    const [LayerList, Expand] = await (loadModules(['esri/widgets/LayerList', 'esri/widgets/Expand']) as Promise<
        layerModules
    >);

    const supportingLayerList = new LayerList({
        view,
        listItemCreatedFunction: i => {
            const item = i.item as __esri.ListItem;

            if (!item.layer.id.startsWith('demo')) {
                const actionsSections = [
                    [
                        {
                            title: 'Increase opacity',
                            className: 'esri-icon-up',
                            id: 'increase-opacity'
                        },
                        {
                            title: 'Decrease opacity',
                            className: 'esri-icon-down',
                            id: 'decrease-opacity'
                        }
                    ]
                ] as any;

                if (item.layer.type === 'group') { // don't include full extent button for group layers
                    item.actionsSections = actionsSections;
                } else {
                    const sections = [
                        [
                            {
                                title: 'Go to full extent',
                                className: 'esri-icon-zoom-out-fixed',
                                id: 'full-extent'
                            }
                        ],
                        ...actionsSections
                    ];

                    item.actionsSections = sections as any;
                }
            }
        }
    });

    supportingLayerList.on('trigger-action', evt => {
        if (evt.action.id === 'full-extent') {
            view.goTo(evt.item.layer.fullExtent);
        } else if (evt.action.id === 'increase-opacity') {
            if (evt.item.layer.opacity < 1) {
                evt.item.layer.opacity += 0.125;
            }
        } else if (evt.action.id === 'decrease-opacity') {
            if (evt.item.layer.opacity > 0) {
                evt.item.layer.opacity -= 0.125;
            }
        }
    });

    supportingLayerList.operationalItems.watch('length', () => {
        const layers = supportingLayerList.operationalItems.filter(i => i.layer.id.startsWith(SUPPORT_LAYER_ID_PREFIX));
        supportingLayerList.operationalItems.removeAll();
        supportingLayerList.operationalItems.addMany(layers);
    });

    return new Expand({
        view,
        content: supportingLayerList,
        expandTooltip: 'Supporting Layers'
    });
};

export default CALayerListSupporting;
