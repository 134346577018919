import { loadModules } from 'esri-loader';
import { CAInfoURL } from '../layers/ca/CAInfoLayer';

const CAInfoQuery = async (graphic: __esri.Graphic) => {
    type esriModules = [typeof import('esri/tasks/support/Query'), typeof import('esri/tasks/QueryTask')];

    const [Query, QueryTask] = await (loadModules(['esri/tasks/support/Query', 'esri/tasks/QueryTask']) as Promise<
        esriModules
    >);

    const query = new Query();
    query.returnGeometry = false;
    query.where = `HashId='${graphic.attributes.HashID || graphic.attributes.HashId}'`;
    query.outFields = ['*'];

    const queryTask = new QueryTask({
        url: CAInfoURL
    });

    return queryTask.execute(query);
};

export default CAInfoQuery;
