import { loadModules } from 'esri-loader';
import { getSupportingLayerId } from '../../helpers/CALayerID';

const CACountyLayer = async () => {
    type layerModules = [typeof import('esri/layers/VectorTileLayer')];

    const [VectorTileLayer] = await (loadModules(['esri/layers/VectorTileLayer']) as Promise<layerModules>);
    const title = 'Counties';

    return new VectorTileLayer({
        url: 'https://tiles.arcgis.com/tiles/osXWqrTKrwWBat15/arcgis/rest/services/Counties/VectorTileServer',
        title,
        id: getSupportingLayerId(title),
        visible: false
    });
};

export default CACountyLayer;
