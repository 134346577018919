import { loadModules } from 'esri-loader';

const CABasemapWidget = async (view: __esri.View) => {
    type homeModules = [typeof import('esri/widgets/BasemapGallery'), typeof import('esri/widgets/Expand')];

    const [BasemapGallery, Expand] = await (loadModules(['esri/widgets/BasemapGallery', 'esri/widgets/Expand']) as Promise<homeModules>);

    const gallery = new BasemapGallery({
        view: view
    });

    return new Expand({
        view: view,
        content: gallery,
        expandTooltip: 'Basemaps'
    });
};

export default CABasemapWidget;
