const CASortLayers = (a: __esri.Layer, b: __esri.Layer) => {
    if (a.title.toLowerCase() > b.title.toLowerCase()) {
        return -1
    }

    if (a.title.toLowerCase() < b.title.toLowerCase()) {
        return 1
    }

    return 0
}

export default CASortLayers;
