import {useState} from 'react';

/**
 * This hook is a helper for Input elements. It returns a ```setValue``` and ```onChange``` methods
 * and the ```value```.
 * @param {string} initalValue - The initial vlaue for the input.
 */
export const useInputValue = (initalValue: string | number) => {
    const [value, setValue] = useState(initalValue.toString());

    return {
        value,
        setValue,
        onChange: (e: React.FormEvent) => {
            const input = e.target as HTMLInputElement;
            setValue(input.value || input.innerText);
        }
    };
};
