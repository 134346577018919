import { loadModules } from 'esri-loader';

export const CATextSymbol = async () => {
    type textModules = [typeof import('esri/symbols/TextSymbol')];

    const [TextSymbol] = await (loadModules(['esri/symbols/TextSymbol']) as Promise<textModules>);

    return new TextSymbol({
        haloSize: 3,
        haloColor: [255, 255, 255],
        yoffset: -4,
        color: [0, 0, 0],
        font: {
            size: 9,
            weight: 'bold'
        }
    });
};

export const CALayerLabels = async () => {
    type labelModules = [typeof import('esri/layers/support/LabelClass')];

    const [LabelClass] = await (loadModules(['esri/layers/support/LabelClass']) as Promise<labelModules>);

    const labelClass = new LabelClass({
        symbol: await CATextSymbol(),
        labelPlacement: 'above-center',
        minScale: 1000000,
        labelExpressionInfo: {
            expression: '$feature.NAME'
        }
    });

    return labelClass;
};

export const CXYLayerLabels = async () => {
    type labelModules = [typeof import('esri/layers/support/LabelClass')];

    const [LabelClass] = await (loadModules(['esri/layers/support/LabelClass']) as Promise<labelModules>);

    const labelClass = new LabelClass({
        symbol: await CATextSymbol(),
        labelPlacement: 'above-center',
        minScale: 1000000,
        labelExpressionInfo: {
            expression: '$feature.Label + " " + $feature.StoreNumber'
        }
    });

    return labelClass;
};
