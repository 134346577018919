import React from 'react';
import ReactDom from 'react-dom';
import { loadModules } from 'esri-loader';
import format from 'number-format.js';
import CAFormatDate from '../helpers/CAFormatDate';
import { CAViewOnGooglePopupAction, CANearbyStatsPopupAction } from './CAPoupActions';

const getStreetAddress = (address?: string) => {
    if (address) {
        return <div>{address}</div>;
    }

    return null;
};

const getCityStatePostal = (city?: string, state?: string, postal?: string) => {
    let cityState = `${city}, ${state}`;

    if (!city || !state) {
        cityState = `${city}${state}`;
    }

    return (
        <div>
            {cityState} {postal}
        </div>
    );
};

const getURL = (title: string, href?: string) => {
    if (href) {
        return (
            <div>
                <a href={href} target="_blank" rel="noopener noreferrer">
                    {title}
                </a>
            </div>
        );
    }

    return null;
};

export const CAInfoContent = (graphic: __esri.Graphic) => {
    const node = document.createElement('div');
    const atts = graphic.attributes;
    const street = atts.STREET_ADD;
    const city = atts.CITY;
    const state = atts.STATE;
    const postal = atts.ZIP_CODE;
    const smTotal = atts.SM2014_TOTAL_SELL ? format('$###,###.', atts.SM2014_TOTAL_SELL) : 'N/A';
    const smWeek = atts.SM2014_TOTAL_WEEK ? format('$###,###.', atts.SM2014_TOTAL_WEEK) : 'N/A';
    const smSupplier = atts.SM_2014_Supplier;
    const sqft = atts.Sq_Ft ? format('###,###.', atts.Sq_Ft) : 'N/A';
    const weeklyVol = atts.Weekly_Volume ? format('$###,###.', atts.Weekly_Volume) : 'N/A';
    const casingUrl = atts.Casing_URL;
    const otherUrl = atts.Other_URL;
    const openDate = CAFormatDate(atts.Date_Opened);
    const closeDate = CAFormatDate(atts.Date_Closed);
    const sourceDate = CAFormatDate(atts.Info_Date);
    const source = atts.Source;

    const content = (
        <React.Fragment>
            <div className="d-flex justify-content-between">
                <div>
                    {getStreetAddress(street)}
                    {getCityStatePostal(city, state, postal)}
                </div>
                <div>
                    <div>Open Date: {openDate}</div>
                    <div>Close Date: {closeDate}</div>
                </div>
            </div>
            <div className="d-flex justify-content-between mt-3">
                <div>
                    <div>SM 2014 Total Selling: {smTotal}</div>
                    <div>SM 2014 Total Weekly: {smWeek}</div>
                    <div>SM 2014 Supplier: {smSupplier}</div>
                    <div>SF: {sqft}</div>
                    <div>Weekly Volume: {weeklyVol}</div>
                    {getURL('Casing →', casingUrl)}
                    {getURL('Other →', otherUrl)}
                </div>
                <div className="text-right">
                    <div>Source: {source ?? 'N/A'}</div>
                    <div>Date: {sourceDate}</div>
                </div>
            </div>
        </React.Fragment>
    );

    ReactDom.render(content, node);

    return node;
};

const CAInfoPopup = async () => {
    type esriModules = [typeof import('esri/PopupTemplate')];

    const [PopupTemplate] = await (loadModules(['esri/PopupTemplate']) as Promise<esriModules>);

    return new PopupTemplate({
        outFields: ['*'],
        title: (feature: __esri.Feature) => {
            const name = feature.graphic.attributes.NAME;
            const storeNum = feature.graphic.attributes.STORE_NUMB;
            return `<strong>${name}${storeNum ? ' - ' + storeNum : ''}</strong>`;
        },
        content: (feature: __esri.Feature) => {
            return CAInfoContent(feature.graphic);
        },
        overwriteActions: true,
        actions: [await CAViewOnGooglePopupAction(), await CANearbyStatsPopupAction()]
    });
};

export default CAInfoPopup;
