import { loadModules } from 'esri-loader';
import { getSupportingLayerId } from '../../helpers/CALayerID';

const CACrimeLayer = async () => {
    type LayerModules = [typeof import('esri/layers/MapImageLayer'), typeof import('esri/PopupTemplate')];

    const [MapImageLayer, PopupTemplate] = await (loadModules([
        'esri/layers/MapImageLayer',
        'esri/PopupTemplate'
    ]) as Promise<LayerModules>);

    const popupTemplate = new PopupTemplate({
        outFields: ['ID', 'NAME', 'CRMCYTOTC'],
        title: (feature: __esri.Feature) => {
            // NOTE: This is accessing a private property to get the layer name. Double check this still works when updating the Esri API
            const layerName = (feature.graphic as any).sourceLayer.title;

            if (layerName === 'County') {
                return feature.graphic.attributes.NAME;
            }

            if (layerName === 'ZIP Code') {
                return `${feature.graphic.attributes.ID} (${layerName})`;
            }

            return `${feature.graphic.attributes.NAME} (${layerName})`;
        },
        content:
            'In comparison to the national average of 100, the overall crime index in this area <strong>{CRMCYTOTC}</strong>.'
    });

    const title = 'Crime Index';

    const crimeLayer = new MapImageLayer({
        portalItem: {
            id: 'b3802d8a309544b791c2304fece864dc'
        },
        opacity: 0.5,
        id: getSupportingLayerId(title),
        visible: false,
        title,
        listMode: 'hide-children'
    });

    crimeLayer.on('layerview-create', () => {
        crimeLayer.sublayers.forEach(s => {
            s.popupTemplate = popupTemplate;
            if (s.id === 0) {
                s.popupEnabled = false;
            }
        });
    });

    return crimeLayer;
};

export default CACrimeLayer;
