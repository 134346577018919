import { loadModules } from 'esri-loader';

const CSScaleBarWidget = async (view: __esri.View) => {
    type scaleModules = [typeof import('esri/widgets/ScaleBar')];

    const [ScaleBar] = await (loadModules(['esri/widgets/ScaleBar']) as Promise<scaleModules>);

    return new ScaleBar({
        view,
        style: 'line'
    });
};

export default CSScaleBarWidget;
