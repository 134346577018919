import React from 'react';
import './App.css';
import { Container } from 'reactstrap';
import CAMap from './CAMap';
import './sass/App.scss';
import './sass/CATheme.scss';

const App: React.FC = () => {
  return (
    <Container fluid className="h-100 w-100 px-0">
      <CAMap />
    </Container>
  );
};

export default App;
