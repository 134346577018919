import { loadModules } from 'esri-loader';
import { CXYLayerLabels } from '../../helpers/CALayerLabels';
import CXYPopup from '../../popups/CXYPopup';

export const bigBoxLayerTitle = 'Big Box Retail';

const CXBigBoxLayer = async () => {
    type layerModules = [typeof import('esri/layers/FeatureLayer')];

    const [FeatureLayer] = await (loadModules(['esri/layers/FeatureLayer']) as Promise<layerModules>);

    return new FeatureLayer({
        url: 'https://services8.arcgis.com/osXWqrTKrwWBat15/arcgis/rest/services/CXY_Big_Box_Retail/FeatureServer/0',
        title: bigBoxLayerTitle,
        opacity: 1.0,
        visible: true,
        definitionExpression: '1<>1',
        labelsVisible: false,
        legendEnabled: false,
        labelingInfo: [await CXYLayerLabels()],
        popupTemplate: await CXYPopup()
    });
};

export default CXBigBoxLayer;
