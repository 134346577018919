import { loadModules } from 'esri-loader';
import { getSupportingLayerId } from '../../helpers/CALayerID';

const CACBSALayer = async () => {
    type layerModules = [typeof import('esri/layers/FeatureLayer')];

    const [FeatureLayer] = await (loadModules(['esri/layers/FeatureLayer']) as Promise<layerModules>);
    const title = 'CBSA';

    return new FeatureLayer({
        url: 'https://services8.arcgis.com/osXWqrTKrwWBat15/arcgis/rest/services/CBSA/FeatureServer/0',
        outFields: ['FID', 'CSAFP', 'CBSAFP', 'NAME'],
        title,
        id: getSupportingLayerId(title),
        opacity: 1.0,
        visible: false,
        popupTemplate: {
            title: 'CBSA',
            content: '{NAME}'
        }
    });
};

export default CACBSALayer;
