import React, { useEffect } from 'react';
import CABasemapWidget from './CABasemapWidget';
import CALayerListSupporting from './CALayerListSupporting';
import CALocateWidget from './CALocateWidget';
import CAMeasureWidget from './CAMeasureWidget';
import CAScaleBarWidget from './CAScaleBarWidget';
import CAScreenshotWidget from './CAScreenshotWidget';
import CASearchWidget from './CASearch';
import CALayerListByFilterWidget from './CALayerListByFilterWidget';
import { bigBoxLayerTitle } from '../layers/cxy/CXBigBoxLayer';
import { chainGroceryTitle } from '../layers/cxy/CXYChainGroceryLayer';
import CALayerListCAWidget from './CALayerListCAWidget';
import CAExportCSVWidget from './CAExportCSVWidget';
import CABufferWidget from './CABufferWidget';
import CALoadingWidget from './CALoadingWidget';
import CALegendWidget from './CALegendWidget';
import { CA_BOTTOM_RIGHT_WIDGET_ID } from '../helpers/CAConstants';
import CAChainGroceryWidget from './CAChainGroceryWidget';

interface ICFWidgetsProps {
    view?: __esri.MapView | __esri.SceneView;
    map?: __esri.Map;
}

const topLeft = async (view: __esri.MapView | __esri.SceneView) => {
    const basemapGallery = await CABasemapWidget(view);
    const locate = await CALocateWidget(view);
    const search = await CASearchWidget(view);
    const csvDownload = CAExportCSVWidget({ view });
    const loadingWidget = CALoadingWidget(view);
    // Add them this way so we can make sure the mhi widget (instantiated elsewhere shows at the bottom)
    [locate, search, basemapGallery, csvDownload].reverse().forEach(c => {
        view.ui.add({
            component: c,
            position: 'top-left',
            index: 1
        });
    });

    view.ui.add({
        component: loadingWidget,
        position: 'top-left',
        index: 99
    });
};

const topRight = async (view: __esri.MapView | __esri.SceneView) => {
    const screenShot = CAScreenshotWidget({ view });
    view.ui.add([screenShot], 'top-right');
};

const bottomRight = async (view: __esri.MapView | __esri.SceneView) => {
    const bufferWidget = await CABufferWidget(view);
    const measureWidget = await CAMeasureWidget(view);
    const supportingLayerList = await CALayerListSupporting(view);
    const caLayerList = await CALayerListCAWidget(view);

    const bigBoxWidget = await CALayerListByFilterWidget({
        view,
        layerTitle: bigBoxLayerTitle,
        expandIconClass: 'fa fa-store'
    });

    const chainGroceryWidget = await CAChainGroceryWidget({
        view,
        layerTitle: chainGroceryTitle,
        expandIconClass: 'fa fa-shopping-cart'
    });

    const bottomRightWidgets = [
        measureWidget,
        bufferWidget,
        supportingLayerList,
        bigBoxWidget,
        chainGroceryWidget,
        caLayerList
    ];
    bottomRightWidgets.forEach(e => (e.group = CA_BOTTOM_RIGHT_WIDGET_ID));

    view.ui.add(bottomRightWidgets.reverse(), 'bottom-right');
};

const bottomLeft = async (view: __esri.MapView | __esri.SceneView) => {
    const scaleBar = await CAScaleBarWidget(view);
    const legend = await CALegendWidget(view);
    view.ui.add([legend, scaleBar], 'bottom-left');
};

const BSLAWidgets: React.FC<ICFWidgetsProps> = props => {
    useEffect(() => {
        if (props.view) {
            const view = props.view;
            const init = () => {
                view.when(async () => {
                    // Top Left Widgets
                    topLeft(view);

                    // Bottom Left Widgets
                    bottomLeft(view);

                    // Bottom Right Widgets
                    bottomRight(view);

                    // Top Right Widgets
                    topRight(view);
                });
            };

            init();
        }
    }, [props.view]);
    return null;
};

export default BSLAWidgets;
