import { loadModules } from 'esri-loader';

export const CAViewOnGooglePopupAction = async () => {
    type actionModules = [typeof import('esri/support/actions/ActionButton')];

    const [ActionButton] = await (loadModules(['esri/support/actions/ActionButton']) as Promise<actionModules>);

    return new ActionButton({
        title: 'View in Google',
        id: 'view-in-google',
        className: 'fab fa-google'
    });
}

export const CANearbyStatsPopupAction = async () => {
    type actionModules = [typeof import('esri/support/actions/ActionButton')];

    const [ActionButton] = await (loadModules(['esri/support/actions/ActionButton']) as Promise<actionModules>);

    return new ActionButton({
        title: 'Nearby Stats',
        id: 'nearby-stats',
        className: 'esri-icon-chart'
    });
}

