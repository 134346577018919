import { loadModules } from 'esri-loader';
import { getCALayerId } from '../../helpers/CALayerID';
import classNames from 'classnames';
import React from 'react';
import ReactDOM from 'react-dom';
import { Button, Col, Row } from 'reactstrap';
import { DateTime } from 'luxon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faNewspaper } from '@fortawesome/free-solid-svg-icons';
import { CATextSymbol } from '../../helpers/CALayerLabels';

const outFields = [
    'ACCURACY',
    'AUDITDATE',
    'CITY',
    'DESCLOCATION',
    'DEVELOPER',
    'DEVTYPE',
    'NAME',
    'NAMECENTER',
    'OBJECTID',
    'OPENDATE',
    'OPENDATEAPPROX',
    'PROJECTURL',
    'SIZESF',
    'SIZESFTYPE',
    'SOURCE',
    'STATE',
    'STATUS',
    'ZIP',
    'county',
    'NOTES'
];

/**
 * Returns a formatted link to the developers site
 * @param dev Name of the developer
 * @param link URL to the development site
 */
const getDevLink = (dev?: string, link?: string) => {
    if (dev && dev !== 'null') {
        // service may return a string called null, not an actual null.
        const devTitle = <div className="text-muted small">Developer</div>;
        if (link && link !== 'null') {
            return (
                <React.Fragment>
                    {devTitle}
                    <a href={link} target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faBuilding} className="mr-1 pgr-popup-link-icon" />
                        <span className="d-none d-md-inline">{dev}</span>
                    </a>
                </React.Fragment>
            );
        } else {
            return (
                <div>
                    {devTitle}
                    {dev}
                </div>
            );
        }
    }

    return undefined;
};

/**
 * Returns a formatted opendate given a timestamp. Otherwise returns undefined.
 * @param timestamp
 */
const getOpenDate = (timestamp?: number) => {
    if (timestamp && !isNaN(timestamp)) {
        const dt = DateTime.fromMillis(timestamp);
        return dt.toLocaleString(DateTime.DATE_MED);
    }

    return undefined;
};

/**
 * Returns a formatted string for the store size.
 * @param size Store Size
 * @param code Store size coded value domain code
 */
const getSize = (size?: number, code?: number) => {
    if (size) {
        const formattedSize =
            size.toLocaleString(undefined, {
                minimumFractionDigits: 0
            }) + ' SF';

        if (code && code === 1) {
            return `${formattedSize} (Approx.)`;
        } else {
            return formattedSize;
        }
    }

    return 'N/A';
};

const getDevType = (feature: __esri.Feature) => {
    const devType = isNaN(feature.graphic.attributes.DEVTYPE) // this is really strange. WinCo Foods status is decoded at some point but not sure where. Possibly others. Just add a check here whether the status is a number or not and decode as necessary
        ? feature.graphic.attributes.DEVTYPE
        : ((feature.graphic.layer as __esri.FeatureLayer).getFieldDomain('DEVTYPE') as __esri.CodedValueDomain).getName(
              feature.graphic.attributes.DEVTYPE
          );

    return devType;
};

const getStatusDesc = (statusAttribute: any, layer: __esri.FeatureLayer) => {
    return isNaN(statusAttribute) // this is really strange. WinCo Foods status is decoded at some point but not sure where. Possibly others. Just add a check here whether the status is a number or not and decode as necessary
        ? statusAttribute
        : (layer.getFieldDomain('STATUS') as __esri.CodedValueDomain).getName(statusAttribute);
};

const PGRLayer = async () => {
    type esriModules = [
        typeof import('esri/layers/FeatureLayer'),
        typeof import('esri/PopupTemplate'),
        typeof import('esri/layers/support/LabelClass'),
        typeof import('esri/Color')
    ];

    const [FeatureLayer, PopupTemplate, LabelClass, Color] = await (loadModules([
        'esri/layers/FeatureLayer',
        'esri/PopupTemplate',
        'esri/layers/support/LabelClass',
        'esri/Color'
    ]) as Promise<esriModules>);

    const title = 'Planned Grocery';

    // Labels
    const nameExpression = '$feature.NAME';
    const statusExpression =
        '"(" + Decode($feature.STATUS, 0, "Built", 1, "Under Construction", 2, "Proposed", 3, "Planned", "") + ")"';
    const opendateExpression = 'Text($feature.OPENDATE, "Y-MMM-D")';
    const openExpression = `IIf($feature.OPENDATE > 0, ${opendateExpression}, $feature.OPENDATEAPPROX)`;

    const textSymbol = (await CATextSymbol()).clone();
    textSymbol.color = new Color('429c3e');
    textSymbol.horizontalAlignment = 'center';
    
    const labelClass = new LabelClass({
        symbol: textSymbol,
        labelPlacement: 'center-right',
        minScale: 1000000,
        labelExpressionInfo: {
            expression: `${nameExpression} + TextFormatting.NewLine + ${statusExpression} + TextFormatting.NewLine + ${openExpression}`
        }
    });


    const fLayer = new FeatureLayer({
        url: 'https://services1.arcgis.com/aUqH6d4IMis39TBB/arcgis/rest/services/BD_FUTURE_RETAIL/FeatureServer/0',
        title,
        id: getCALayerId(title),
        visible: false,
        definitionExpression: '(STATUS <> 99 AND (OPENDATE IS NULL OR OPENDATE >= CURRENT_TIMESTAMP - 180))',
        labelingInfo: [labelClass]
    });

    const popupTemplate = new PopupTemplate({
        outFields,
        lastEditInfoEnabled: false,
        title: (feature: __esri.Feature) => {
            const storeName = document.createElement('span');
            storeName.innerHTML = feature.graphic.attributes.NAME;

            const status = getStatusDesc(
                feature.graphic.attributes.STATUS,
                feature.graphic.layer as __esri.FeatureLayer
            );

            return `${storeName.outerHTML} (${status.replace('Under ', '')})`;
        },
        content: (feature: __esri.Feature) => {
            const node = document.createElement('div');

            let logoSource = undefined;
            const renderer = fLayer.renderer as __esri.UniqueValueRenderer;

            renderer
                .getUniqueValueInfo(feature.graphic)
                .then(uniqueValueInfo => {
                    logoSource = (uniqueValueInfo.symbol as __esri.PictureMarkerSymbol).url;
                })
                .catch(err => console.error(err));

            const logoClasses = classNames({
                'text-danger':
                    feature.graphic.attributes.STATUS === 99 || feature.graphic.attributes.STATUS === 'Dead Deal',
                'd-flex align-items-center': true,
                'mb-1': true
            });

            const popupConent = (
                <React.Fragment>
                    <Row className="text-muted small mx-0">
                        <Col className="px-0" xs="4">
                            Est. Open Date
                        </Col>
                        <Col className="px-0 text-center" xs="3">
                            Dev Type
                        </Col>
                        <Col className="px-0 text-right" xs="5">
                            Size
                        </Col>
                    </Row>
                    <Row className="mx-0">
                        <Col className="px-0" xs="4">
                            {getOpenDate(feature.graphic.attributes.OPENDATE) ||
                                feature.graphic.attributes.OPENDATEAPPROX ||
                                'N/A'}
                        </Col>
                        <Col className="px-0 text-center" xs="3">
                            {getDevType(feature)}
                        </Col>
                        <Col className="text-right px-0" xs="5">
                            {getSize(feature.graphic.attributes.SIZESF, feature.graphic.attributes.SIZESFTYPE)}
                        </Col>
                    </Row>
                    <div className="mt-2">
                        <div className={logoClasses}>
                            <img
                                src={logoSource}
                                alt={`${feature.graphic.attributes.NAME} Logo`}
                                height="24"
                                width="24"
                                className="mr-2"
                            />
                            <span>{feature.graphic.attributes.NAME}</span>
                            <span className="ml-1">
                                (
                                {getStatusDesc(
                                    feature.graphic.attributes.STATUS,
                                    feature.graphic.layer as __esri.FeatureLayer
                                )}
                                )
                            </span>
                        </div>
                        {feature.graphic.attributes.DESCLOCATION}
                        <br />
                        {feature.graphic.attributes.CITY}, {feature.graphic.attributes.STATE}{' '}
                        {feature.graphic.attributes.ZIP}
                    </div>
                    <div className={feature.graphic.attributes.NOTES ? 'mt-2' : 'd-none'}>
                        <div className="text-muted small">Notes</div>
                        {feature.graphic.attributes.NOTES}
                    </div>
                    <div className="mt-2">
                        {getDevLink(feature.graphic.attributes.DEVELOPER, feature.graphic.attributes.PROJECTURL)}
                    </div>
                    <div className="mt-2 d-flex justify-content-between">
                        <Button
                            className="p-0"
                            color="link"
                            title="Source"
                            onClick={(evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                                evt.preventDefault();
                                window.open(feature.graphic.attributes.SOURCE, '_blank', 'noopener');
                            }}
                        >
                            <FontAwesomeIcon icon={faNewspaper} className="mr-1 pgr-popup-link-icon" />
                            <span className="d-none d-md-inline">Source</span>
                        </Button>
                    </div>
                </React.Fragment>
            );
            ReactDOM.render(popupConent, node);
            return node;
        }
    });

    fLayer.popupTemplate = popupTemplate;

    return fLayer;
};

export default PGRLayer;
